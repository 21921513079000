// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { DealsService } from 'src/app/services/deals.service';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-featured-deals-small',
  templateUrl: './featured-deals-small.component.html',
  styleUrls: ['./featured-deals-small.component.scss']
})
export class FeaturedDealsSmallComponent implements OnInit, OnDestroy {
  public title: string;
  locationsArray: DealsModel[] = [];
  Search: SearchModel = new SearchModel();
  @Input() FilterData: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);
  @Input() dealsData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  facebook: string;
  twitter: string;
  linkedin: string;
  email : string;
  break : string;
  customOptions: OwlOptions;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      autoWidth:true,
      margin:20,
      dots: true,
      navSpeed: 700,
      navText: ['', ''],
      autoHeight: true,
      center: true,
      items: 1,
      responsive: {
        0: {
          items: 1
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        },
        1024: {
          items: 3
        },
        1400: {
          items: 3 
        }
      },
      nav: false
    };
    this.facebook = "https://www.facebook.com/sharer/sharer.php?u=southafrica.net/za/en/";
    this.twitter = "https://twitter.com/intent/tweet?text=" + window.location.href + " " + " -";
    this.linkedin = "http://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
    this.email = window.location.href;
    this.break = "-";

    //this.search();
    this.FilterData.subscribe(result => {
      this.Search = result;
      this.Search.province = (this.Search.province === "Select province" || this.Search.province === null) ? '' : result.province.toLowerCase().replace(' ','-').trim(); 
      this.Search.packageType = (this.Search.packageType === '0' || this.Search.packageType === null) ? '' : this.Search.packageType;
      this.Search.experienceType = (this.Search.experienceType === '0' || this.Search.experienceType === null) ? '' : this.Search.experienceType;
      this.Search.city = (this.Search.city === "City(optional)" || this.Search.city === null) ? '' : this.Search.city;
      this.Search.discount = (this.Search.discount === '' || this.Search.discount === null) ? '' : this.Search.discount;
      this.Search.deal_ids = (this.Search.deal_ids === '' || this.Search.deal_ids === null) ? '' : this.Search.deal_ids;
      //this.search();
    });
    this.dealsData.subscribe(results => {
      let filteredData: DealsModel[] = results;
        
        if(this.Search.deal_ids != null && this.Search.deal_ids != ""){
            alert("TEST")
            var split_ids = this.Search.deal_ids.split(",");
            filteredData = [];
            
            if(split_ids.length > 0){
                for(let item of split_ids){
                    
                    let new_entry = results.filter(result => result.entry_id === item);
                    //console.log(new_entry);
                    if(new_entry.length > 0){
                        filteredData.push(new_entry[0]);
                    }
                    
                }
                
            }
        }
        if(filteredData != undefined)
        this.locationsArray = filteredData.
        filter(result => 
        result.status == "open" &&
        new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
        new Date(Number(result.expiration_date)*1000) >= new Date() && //Expiry date
        (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
        (result.cf_package_type === this.Search.packageType || this.Search.packageType === "") &&
        (result.cf_experience_type === this.Search.experienceType || this.Search.experienceType === "") &&
        (result.cf_campaign === this.Search.campaign || this.Search.campaign === "") &&
        (Number(result.cf_discount) > Number(this.Search.discount) || this.Search.discount === "") &&
        (Number(result.cf_price) <= Number(this.Search.price)  || this.Search.price === "") &&
        //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
        (result.cf_destinations[0]?.mx_province === this.Search.province.toLocaleLowerCase() || this.Search.province === "" || this.Search.province === "south-africa") &&
        ((result.cf_destinations[0]?.mx_city === this.Search.city || this.Search.city == "") && result.cf_destinations[0]?.mx_city != "")
        );
        this.locationsArray = this.locationsArray.sort((x, y) => +new Date(Number(y.date_deal_approved)*1000) - +new Date(Number(x.date_deal_approved)*1000));
        
        if(this.locationsArray.length > 50){
            this.locationsArray = this.locationsArray.slice(0,50);
            
        }
        for (let i of this.locationsArray) {
            if(i.cf_images[0].mx_image.includes("(") && !i.cf_images[0].mx_image.includes("\\")){
              i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(/\(/g,'\\(').replace(/\)/g,'\\)');
            }
            else if(i.cf_images[0].mx_image === "") 
            {
              i.cf_images[0].mx_image = "assets/images/image.png";
            }
            if(i.cf_images[0].mx_image.includes(" ")) 
            {
              i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(' ','%20');
            }
        }
        
        
        });
  }

  
  search()
  {
    this.locationsArray = this.data.getAllByDealsByIds(this.Search);
    this.locationsArray = this.locationsArray.sort((x, y) => +new Date(Number(y.date_deal_approved)*1000) - +new Date(Number(x.date_deal_approved)*1000));
        
        if(this.locationsArray.length > 50){
            this.locationsArray = this.locationsArray.slice(0,50);
        }
        
    //alert(this.locationsArray.length);
    for (let i of this.locationsArray) {
      if(i.cf_images[0]?.mx_image.includes("(")){
        //i.cf_images[0].mx_image = "assets/images/image.png";
      }
    }
  }
  
  goToDeals(deal: string){
    
    sessionStorage.setItem('dealId', deal);
    window.location.href = "/deals/"+deal;
    
  }
  public ngOnDestroy() : void {
   
  }
  
}
  
