// Angular imports
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { CampaignModel } from 'src/app/models/main/campaign/campaign.model';
import { BannersModel } from 'src/app/models/main/deals/banners.model';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { TravelWeekModel } from 'src/app/models/main/travelweek/travelweek.model';
import { CampaignService } from 'src/app/services/campaign.service';

// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';

@Component({
  selector: 'travelweeknew-page',
  templateUrl: './travelweeknew-page.component.html',
  styleUrls: ['./travelweeknew-page.component.scss'],
  
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TravelWeeknewPage implements OnInit {
  public imgsrc: any;
  public background: any;
  public FilterData: InfomapModel = new InfomapModel();
  public filterData: InfomapModel = new InfomapModel();
  public Search: SearchModel = new SearchModel("","","","","","","");
  public TravelWeek: TravelWeekModel[];
  public searchFilter: BehaviorSubject<InfomapModel | undefined> = new BehaviorSubject<InfomapModel | undefined>(undefined);
  public searchModel: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);
  public dealsData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  public dealsDataFull: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  public dealsDataSingle: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  public popularexperiencesCount: any[] = [];
  public popularexperiencesfCount: any[] = [];
  public topExperiences: any[] = [];
  public popularexperiences: DealsModel[];
  public provinceChange: string;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  
  provname = "Northern Cape";
  prodesc = "Northern Cape Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod";
  bannersArray: BehaviorSubject<BannersModel[] | undefined> = new BehaviorSubject<BannersModel[] | undefined>(undefined);
  public config: CountdownConfig = {
    leftTime: 16000000,
    format: 'dd:HH:mm:ss',
    prettyText: (text) => {
      //alert(text);
      return text;
    },
  };
  
  constructor(
    private data: DealsService,
    private campaigndata: CampaignService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    ) {
  }

  public ngOnInit() : void {
    
    
    this.TravelWeek = this.campaigndata.getTravelWeekById("123");
    
    //debugger;
    //alert(this.getDateCount());
    var headings = "Days:Hours:Minutes:Seconds";
    var headings_array = headings.split(':');
    var count = 0;
    this.config = {
      leftTime: this.getDateCount(),
      format: 'dd:HH:mm:ss',
      prettyText: (text) => {
        //alert(text);
        return text
          .split(':')
          .map((v) => {
              var test = `<span class="cd_number">${v} <br/><span class="inner"">${headings_array[count]}</span></span>`;
              
              ++count;
              if(count === 4){
                 count = 0;
              }
              return test;
          })
          .join('');
      },
    };
    //let pget = sessionStorage.getItem('provinceTo');
    let pget = "Select province";
    let type = sessionStorage.getItem('type');;
    let theme = sessionStorage.getItem('theme');
    let city = sessionStorage.getItem('cityTo');

    if(pget === null || pget === "")
        location.href = "/";

    let province = (pget.trim() == 'Select province') ? 'south-africa' : pget.trim();
    pget = (pget.trim() == 'Select province') ? 'south-africa' : pget.trim();
    this.imgsrc = 'assets/images/province/laughing.png';
    this.background = (`url(${this.imgsrc}) no-repeat`);

    this.filterData.imgName = 'hut'; //TODO: Change to Video per province.
    this.filterData.ProvinceUrlLink = this.TravelWeek[0].video_url;
    this.filterData.ProvinceText = this.TravelWeek[0].title; 
    //TODO: Section/PRovince description
    this.filterData.ProvinceDescription = this.TravelWeek[0].sub_title;

    this.getAllFilterLive(this.Search);
    this.FilterData = this.filterData;
    this.searchFilter.next(this.FilterData);

    this.Search.packageType = theme;
    this.Search.province = pget;
    this.Search.city = city;
    this.Search.experienceType = type;
    //this.Search.discount = "0";
    this.Search.campaign = "travel-week";

    
    //TODO: Add type to filter;
    

    if(this.bannersArray === null)
        this.bannersArray.next(this.data.getAllBanners());

    this.searchModel.next(this.Search);
      
    this.getBanners();
    //this.titleService.setTitle("Travel week");
  }
  getDateCount(): any{
    var date1 = new Date();
    var date2 = new Date("09-13-2021");
      
    // To calculate the time difference of two dates
    var Difference_In_Time =  (date2.getTime() - date1.getTime()) / 1000;
      
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      
    //To display the final no. of days (result)
    //document.write("Total number of days between dates  <br>"
    //              + date1 + "<br> and <br>" 
    //              + date2 + " is: <br> " 
    //              + Difference_In_Days);
    return Difference_In_Time;
  }
  getAllFilterLive(search : SearchModel): void{
    const experiences = this.data.getAllExperiences();
    var data2 : any[] = [];
    
    this.activatedRoute.data.subscribe(response => {
       var data = response.deals;
       
       if(data != undefined){
        this.dealsData.next(data.
          filter(result => 
          result.status == "open" &&
          (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
          new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
          (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
          (result.cf_package_type === search.packageType || search.packageType === "") &&
          (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
          (result.cf_campaign === search.campaign || search.campaign === "") &&
          (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
          ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
          //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
          (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
          ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
          ));
          this.activatedRoute.data.subscribe(response => {
            data2 = response.dealsSingle;
            data2 = data2.filter(result => 
                    result.status == "open" &&
                    (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
                    new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
                    (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
                    (result.cf_package_type === search.packageType || search.packageType === "") &&
                    (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
                    (result.cf_campaign === search.campaign || search.campaign === "") &&
                    (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
                    ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
                    //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
                    (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
                    ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != ""));
                    });

          this.dealsDataFull.next(data);
          this.dealsDataSingle.next(data2);
          this.dealsData.subscribe(result => { 
            this.popularexperiences = result; 
            for (let index = 0; index < this.popularexperiences.length; index++) {
             for (let i = 0; i < experiences.length; i++) {
               var count = 0;
               count = this.popularexperiences.filter(x=>x.cf_experience_type === experiences[i].id).length;
               if(count > 0)
               {
                   this.popularexperiencesCount.push({
                     experience: experiences[i].id,
                     image: experiences[i].image,
                     name: experiences[i].name,
                     Count: count,
                   });  
               }
             }
           }
       
           this.popularexperiencesfCount = this.popularexperiencesCount.filter((v,i) => this.popularexperiencesCount.findIndex(item => item.experience == v.experience) === i);
           const top = this.popularexperiencesfCount.sort((x, y) => y - x).slice(0, 4);
           this.topExperiences = top.sort((a, b) => parseFloat(b.Count) - parseFloat(a.Count));
           }
         
       );
       }
       
    }); 
    
  }
  getNewProvince(province: any){
    this.provinceChange = province;
  }
  getBanners(){
    
    this.bannersArray.next(null);
    this.bannersArray.next(this.data.getAllBanners());
  }
}
