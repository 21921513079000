// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { DealsService } from 'src/app/services/deals.service';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { BannersModel } from 'src/app/models/main/deals/banners.model';

@Component({
  selector: 'app-banner-adds',
  templateUrl: './banner-adds.component.html',
  styleUrls: ['./banner-adds.component.scss']
})
export class BannerAddsComponent implements OnInit, OnDestroy {
  public title: string;
  @Input() public bannersArray: BehaviorSubject<BannersModel[] | undefined> = new BehaviorSubject<BannersModel[] | undefined>(undefined);
  public logo: string;
  public heading: string;
  public descrition: string;
  public link: string;
  public banner: string;
  public innerWidth: any;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public banner_image:any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2,
    
  ) {}

  public ngOnInit() : void {
    // this.innerWidth = window.innerWidth;

    // if(this.innerWidth > 700){
    //   document.getElementById("notmobileads").setAttribute("style", "display:block");
    //   document.getElementById("mobileads").setAttribute("style", "display:none");
    // }else{
    //   document.getElementById("mobileads").setAttribute("style", "display:block");
    //   document.getElementById("notmobileads").setAttribute("style", "display:none");
    // }
    this.bannersArray.subscribe(result=>{
      if (!!result){
        this.logo = result[0].logo;
        this.heading = result[0].name;
        this.descrition = result[0].description;
        this.link = result[0].link;
        this.banner = result[0].banner;
      }

      
    });
    this.banner_fun();
  }

  banner_fun(){
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth <481 && this.getScreenWidth <= 767){
   
      // this.dynamic_cover_image="assets/images/Mobi-414x460-bg.jpg"
      this.banner_image="assets/images/Campaign_Banner_390x532px.png"
      // this.dynamic_cover_image= "assets/images/travelweek2022/travelweek2022_mobile.png"
     
   

    }
    else if(this.getScreenWidth >= 768 && this.getScreenWidth <= 1023){
      
      // this.dynamic_cover_image="assets/images/Tablet-768x450-bg.jpg"
      this.banner_image="assets/images/Campaign_Banner_820x540px.png"
      // this.dynamic_cover_image= "assets/images/travelweek2022/travelweek2022_desktop.png"
      

    }
    else{
        this.banner_image="assets/images/Campaign_Banner_840x550px.png"
    }
   
  }
  public ngOnDestroy() : void {
   
  }
}
  
