// Angular imports
import { Component, OnInit } from '@angular/core';

// React Imports
import {  ActivatedRoute } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NewTravelWeekModel } from 'src/app/models/main/travelweek/newtravelweek.model';
import { TravelWeekService } from 'src/app/services/travelweek.service';

@Component({
  selector: 'travelweek',
  templateUrl: './travelweek.component.html',
  styleUrls: ['./travelweek.component.scss']
})

export class TravelWeekPage implements OnInit {

  TravelWeek: NewTravelWeekModel = new NewTravelWeekModel();

  Busy = false;
  Success = false;
  Failed = false;
  Required = false;
  IsInvalidEmail = false;
  
  constructor(
    private data: TravelWeekService,
    private activatedRoute: ActivatedRoute,
    private dataLayer: GoogleTagManagerService,
    ) {
  }

  public ngOnInit() : void {
    window.location.href = "https://profile.shotleft.co.za";

  }

  IsEmailValid()
  {
    if (this.TravelWeek.email.indexOf('@')>0) 
    return true;
    else
    return false;

  }
  AddTravelWeekUser() {

    const gtmTag = {
      'event': 'travelweek'
    };
    var message;
    this.dataLayer.pushTag(gtmTag);

    console.log("AddTravelWeekUser");

    this.Busy = true;
  
    if (this.TravelWeek.name == undefined || this.TravelWeek.surname == undefined || this.TravelWeek.role == undefined || this.TravelWeek.email == undefined || this.TravelWeek.company_name == undefined || this.TravelWeek.website == undefined || this.TravelWeek.province == undefined ) {
      this.Required = true;
      this.Busy = false;
      this.IsInvalidEmail = false;
    }
    else {
      this.Required = false;
      this.Busy = true;

      if(!this.IsEmailValid()){
        this.Required = false;
        //this.Failed = true;
        this.IsInvalidEmail = true;
        this.Busy = false;
        return;
      }else{
        this.IsInvalidEmail = false;
        this.Required = false;
      }

      this.data.AddTravelWeekUser(this.TravelWeek).subscribe(data => message = data,
        (err) => {
          console.log('Application Failed: ' + JSON.stringify(err));
          this.Busy = false;
          this.Success = false;
          this.Failed = true;
        },
        () => {
          //console.log('Application Success');
          this.Busy = false;
          this.Success = true;
          this.Failed = false;
          this.Required = false;
         
        }
      );
    }
} 
}
