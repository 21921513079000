// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { DealsService } from 'src/app/services/deals.service';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-sidemenu-carousel',
  templateUrl: './sidemenu-carousel.component.html',
  styleUrls: ['./sidemenu-carousel.component.scss']
})
export class SidemenuCarouselComponent implements OnInit, OnDestroy {
  public title: string;
  @Input() public locationsArray: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  
  Search: SearchModel = new SearchModel();
  public logo: string;
  public heading: string;
  public descrition: string;
  public link: string;
  public slides: DealsModel[];
  customOptions: OwlOptions;
  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2,
    
  ) {}

  public ngOnInit() : void {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      autoWidth:true,
      margin:20,
      dots: true,
      navSpeed: 700,
      navText: ['', ''],
      autoHeight: true,
      center: true,
      items: 1,
      responsive: {
        0: {
          items: 1
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        },
        1024: {
          items: 3
        },
        1400: {
          items: 3 
        }
      },
      nav: false
    };

    this.slides = [];
    this.locationsArray?.subscribe(result => {
      if (!!result)
        this.slides = result;

        for (let i of this.slides) {
          
            if(i.cf_images[0].mx_image.includes("(") && !i.cf_images[0].mx_image.includes("\\")){
              i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(/\(/g,'\\(').replace(/\)/g,'\\)');
            }
            else if(i.cf_images[0].mx_image === "") 
            {
              i.cf_images[0].mx_image = "assets/images/image.png";
            }
            if(i.cf_images[0].mx_image.includes(" ")) 
            {
              i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(' ','%20');
            }
        
        }
    });
    
    
  }
  goToDeals(deal: string){
    
    sessionStorage.setItem('dealId', deal);
    window.location.href = "/deals/"+deal;
    
  }
  public ngOnDestroy() : void {
   
  }
}
  
