// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
import { DealsService } from '../../../services/deals.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'itenary-planner',
  templateUrl: './itenary-planner.component.html',
  styleUrls: ['./itenary-planner.component.scss']
})
export class ItenaryPlannerComponent implements OnInit, OnDestroy {
  public title: string;
  public ProvinceText : string;
  public ProvinceDescription : string;
  public ProvinceUrlLink: string;
  public imgsrc: any;
  public background: any;
  public province1: string;
  public province2: string;
  public city1: string;
  public city2: string;
  public citiesFrom: any[] = [];
  public citiesTo: any[] = [];
  public innerWidth: any;
  public dd: DealsModel[] = [];
  public dl_type:string;
  public dl_theme:string;
  public experiences: any;
  public themes: any;
  Search: SearchModel = new SearchModel("","","","","","","");
  @Input() ProvinceName: string;
  @Input() dealsDataFull: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  @ViewChild('target') target;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: CmsService,
    private dealsData: DealsService,
    private renderer:Renderer2,
    private dataLayer: GoogleTagManagerService,
    private dealdata: DealsService,
  ) {}

  public ngOnInit() : void {
    this.imgsrc = 'assets/images/itinerary.png';
    this.background = (`url(${this.imgsrc})`);
    this.ProvinceUrlLink = "https://www.youtube.com/watch?v=LgvseYYhqU0";
    //itinerary planner
    this.province1 = "Select province";
    this.province2 = "Select province";
    this.city1 = "City(optional)";
    this.city2 = "City(optional)";
    //itinerary planner
    this.ProvinceName = "Northern Cape";
    this.ProvinceDescription = "Mzansi has everything you’re looking for and is waiting for you to get out there and explore it. Whether it’s Nightlife or wildlife – whether you want beach sand or bush sand there’s something amazing for everyone right around the corner. It’s your country. Enjoy it.";
    this.innerWidth = window.innerWidth;
    this.experiences = this.dealdata.getAllExperiences();
    this.themes = this.dealdata.getAllPackageTypes();

    if(this.innerWidth > 700){
      document.getElementById("itineraryprovinceMap").setAttribute("style", "display:block");
    }else{
      document.getElementById("itineraryprovinceMap").setAttribute("style", "display:none");
    }
  }
  setImage(image: string){
    this.imgsrc = 'assets/images/'+image+'.png';
    this.background = (`url(${this.imgsrc})`);

    const gtmTag = {
      'province': this.province2,
      'city': this.city2,   
      'event': 'itinerary_step1'
    };
    this.dataLayer.pushTag(gtmTag);
    //console.log(this.dataLayer.getDataLayer());
  }
  setImage2(image: string){
    this.imgsrc = 'assets/images/'+image+'.png';
    this.background = (`url(${this.imgsrc})`);
    var travelType = document.getElementsByClassName('itinerary-card-active');
    
    if(travelType.length > 0){
      
      var type = travelType[0].id.split('-');
      let e = this.experiences.filter(x => x.id === type[1]);
      this.dl_type = e[0].name;
    }
      
    const gtmTag = {
      'province': this.province2,
      'city': this.city2,
      'type': this.dl_type,
      'event': 'itinerary_step2'
    };
    this.dataLayer.pushTag(gtmTag);
    //console.log(this.dataLayer.getDataLayer());
  }
  
  getProvinceFrom(event:any,region:string){
    this.citiesFrom = this.dealsData.getAllCitiesByRegion(region);
    this.province1 = event.target.innerHTML;
    this.city1 = "City(optional)";
    this.controlMap();
  }

  getProvinceTo(event:any,region:string){
    this.getAllCitiesByRegion(region);
    this.province2 = event.target.innerHTML;
    this.city2 = "City(optional)";
    this.controlMap();
  
  }
  getAllCitiesByRegion(region:string): void{
    let arr: any = [];
    let arr2: any = [];
    //alert(region);
    this.dealsDataFull.subscribe(result =>
      {
      this.dd = result;
      //console.log(this.dd);
      if(this.dd != undefined){
        this.dd.filter(x=>x.cf_destinations[0]?.mx_city != "" && x.cf_destinations[0]?.mx_province == region && !x.cf_images[0]?.mx_image.includes("(")).reduce((a, o) => (o.cf_destinations != null && arr.push(o.cf_destinations), a));
        this.citiesTo = Array.from(arr.reduce((m, t) => m.set(t[0].mx_city, t), new Map()).values()); 
        
      }

    });
      
    
      //alert(result_cities.length);
    
}
  getCityFrom(event:any){
    this.city1 = event.target.innerHTML;
  }
  getCityTo(event:any){
    this.city2 = event.target.innerHTML;
  }
  getType(id:string, name:string){
     var el = document.getElementById(id);
     var activeElements = document.getElementsByName(name);
     this.dl_type = name;
     if(activeElements.length > 0){
      var elCount = activeElements.length;
      activeElements.forEach(x=>{
        this.renderer.addClass(x, "itinerary-card");
        this.renderer.removeClass(x,'itinerary-card-active');
      });
      
      
    }

     this.renderer.removeClass(el, "itinerary-card");
     this.renderer.addClass(el, "itinerary-card-active");
  }
  getItinerary(theme_id: string){
      sessionStorage.clear();
      var mapElementFrom = document.getElementById('dropdownMenuButton1');
      var mapElementTo = document.getElementById('dropdownMenuButton2');
      var cityTo = document.getElementById('dropdownMenuButton3');
      var travelType = document.getElementsByClassName('itinerary-card-active');
      
      let t = this.themes.filter(x => x.id === theme_id);

      if(travelType.length > 0){
        
        var type = travelType[0].id.split('-');
        let e = this.experiences.filter(x => x.id === type[1]);
        this.dl_type = e[0].name;
        sessionStorage.setItem('type', type[1]);
      }
      
      //console.log(mapElementTo);
      sessionStorage.setItem('provinceTo', mapElementTo.innerText.trim());
      sessionStorage.setItem('provinceFrom', mapElementFrom.innerText.trim());
      sessionStorage.setItem('cityTo', cityTo.innerText.trim());
      sessionStorage.setItem('theme', theme_id);
      
      //alert(theme_id);
      if(theme_id != '0'){
        this.dl_theme = t[0].name;
      }
      else{
        this.dl_theme = '';
      }
          
      
      const gtmTag = {
        'province': this.province2,
        'city': this.city2,
        'type': this.dl_type,
        'theme': this.dl_theme,
        'event': 'itinerary_step3'
      };
      this.dataLayer.pushTag(gtmTag);
      //console.log(this.dataLayer.getDataLayer());

      var url_id = (mapElementTo.innerText.trim() === "Select province") ? "south-africa": mapElementTo.innerText.trim().replace(' ', '-').toLowerCase();
      window.location.href = "/province/"+url_id;
  }
  getAttribute(event:any){
     
  }
  getAllExperiences(): any[] {
    return this.experiences;
  }
  controlMap(){
    var activeElements = document.getElementsByClassName("activeProvince");
    if(activeElements.length > 0){
      var elCount = activeElements.length;
      for(var count = 1;count <= elCount;count++){
        if(activeElements[0] != null){
          this.renderer.removeClass(activeElements[0],'activeProvince');
        }
      }
      
    }

    var mapElementFrom = document.getElementById(this.province1);
    var mapElementTo = document.getElementById(this.province2);
    
    if(mapElementFrom != null){
        this.renderer.addClass(mapElementFrom, "activeProvince");
    }
    if(mapElementTo != null){
        this.renderer.addClass(mapElementTo, "activeProvince");
    }
  }
  public ngOnDestroy() : void {
   
  }

  
}
