// Angular imports
import { Renderer2 } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

// React Imports
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { ImagesModel } from 'src/app/models/main/deals/images.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { DealsNewModel } from 'src/app/models/main/product/deals.model';
import { DealsService } from 'src/app/services/deals.service';

import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

// Global site text import
import { ProductService } from '../../../services/product.service';
import { HttpClient } from '@angular/common/http';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'tradepartneraccom-page',
  templateUrl: './tradepartneraccom-page.component.html',
  styleUrls: ['./tradepartneraccom-page.component.scss']
})

export class TradePartnerAccomPage implements OnInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;

  map: google.maps.Map;
  currlatitude : number = 0;
  currlongitude : number = 0;
  center = new google.maps.LatLng(this.currlatitude, this.currlongitude);
  marker: google.maps.Marker;
  staticMapUrl: string;
  mapCenter: string;
  mapMarker: string;
  mapSize: string;
  imageUrl: string;

  dealsProductsArray: DealsNewModel[] = [];
  dealsProducts: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);;
  features: any[] = [];
  faqs: any[] = [];
  images: any[] = [];
  location: string;
  productinfo: any[] = [];
  overview: string;
  reviews: any[] = [];
  product: DealsModel;
  title: string;
  address: string;
  bookingLink : string;
  contact_number: string;
  price: string;
  images_dash: ImagesModel[] = [];
  companyLogo: string;
  locations : any = [];
  markerContent : any = [];
  mapMarkers: any = [];
  itinerary : any = [];
  public Search: SearchModel = new SearchModel("","","","","","","");
  public searchModel: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);
  facebook: string;
  twitter: string;
  linkedin: string;
  email : string;
  owlData: DealsModel[] = [];
  province: string;
  mainPhoto: string;
  dealDescription: string;
  currency: string;
  productUrl: string; 

  public dealsDataB: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);

  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: 12
  };
  infoWindow: google.maps.InfoWindow;
  slideConfig = {"slidesToShow": 6, "slidesToScroll": 6};

  constructor(
    private data: ProductService,
    private dealsData: DealsService,
    private renderer:Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataLayer: GoogleTagManagerService,
    ) {
  }
  ngAfterViewInit() {
    this.mapInit();
  }

  public ngOnInit() : void {
    //Add deal variables as per deals dashboard data
    //let dealId = sessionStorage.getItem('dealId');


    this.searchModel.next(this.Search);

    this.activatedRoute.data.subscribe(response => {
        //alert(response.deals.length);
        var data = response.deals;
        let dealId = "";
        this.activatedRoute.params.subscribe(paramsId => {
          dealId = paramsId.id;
          //alert(this.id);
        });
        let filteredData = data.filter(result => result.status == "open" && result.url_title === dealId);
        this.product = filteredData[0];
        //filteredData = data.filter(result => result.status == "open");
        //console.log('dels page');
        //console.log(this.product);
        this.title = this.product.title;
        this.address = this.product.cf_address_line_1+', '+this.product.cf_address_line_2;
        this.contact_number = this.product.cf_product.cf_contact_number;
        this.price = this.product.cf_price;
        this.currency = this.product.cf_currency;
        // // this.location = this.product.cf_destinations[0].mx_address_line_1 + ", " + this.product.cf_destinations[0].mx_address_line_2  + ", " + this.product.cf_destinations[0].mx_city  + ", " + this.product.cf_destinations[0].mx_province;
        let setTitle = this.product.cf_destinations[0].mx_province.split('-');
        let new_title = this.product.cf_destinations[0].mx_province.charAt(0).toUpperCase()+this.product.cf_destinations[0].mx_province.substr(1)
        this.province = new_title;
        if(setTitle.length > 1)
        new_title = setTitle[0].charAt(0).toUpperCase()+setTitle[0].substr(1)+' '+setTitle[1].charAt(0).toUpperCase()+setTitle[1].substr(1);

        this.location = this.product.cf_destinations[0].mx_city  + ", " + new_title;
        this.overview = this.product.cf_deal_description;
        this.dealDescription = this.product.cf_deal_summary;
        this.locations = [];
        this.itinerary = this.product.cf_itinerary_breakdown;
        this.bookingLink = this.product.sf_booking_link;
        this.companyLogo =  (this.product.cf_product.cf_logo.includes("(")) ? "assets/images/icon.png" : this.product.cf_product.cf_logo;
        this.productUrl = this.product.cf_product.cf_website_address;

        if (!this.productUrl.match(/^[a-zA-Z]+:\/\//) && this.productUrl.length > 0)
        {
          this.productUrl = 'http://' + this.productUrl;
        }

        this.Search.province = this.product.cf_destinations[0].mx_province;
        //alert(data.length);
        this.dealsProducts.next(data.sort((x, y) => +new Date(Number(y.date_deal_approved)*1000) - +new Date(Number(x.date_deal_approved)*1000)));
        this.searchModel.next(this.Search);
        //console.log(this.Search);
        if(this.product.cf_address_line_1 !== "" && this.product.cf_address_line_1 !== null){
          this.locations.push({
            lat: Number(this.product.cf_address_line_1),
            lng: Number(this.product.cf_address_line_2)
          });
        }else{
          this.locations.push({
            lat: Number(this.product.cf_destinations[0]?.mx_latitude),
            lng: Number(this.product.cf_destinations[0]?.mx_longitude)
          });
        }


        if(this.product.cf_images.length > 0){

          for (let i of this.product.cf_images) {
            if(i.mx_image.includes(" "))
            {
              i.mx_image = i.mx_image.replace(' ','%20');
            }
            if(i.mx_image.includes("(")){
              i.mx_image = i.mx_image.replace(/\(/g,'\\(').replace(/\)/g,'\\)');
              //alert(i.mx_image);
            }
            else if(i.mx_image === "")
            {
              i.mx_image = "assets/images/image.png";
            }

            if(!i.mx_image.includes("https")){
              i.mx_image = "assets/images/image.png";

            }
            this.images_dash.push(i)
          }
          this.mainPhoto = this.product.cf_images[0].mx_image;
          //console.log("Images dash:");
          //console.log(this.images_dash);
          //this.images_dash = this.product.cf_images;
        }

        this.facebook = "https://www.facebook.com/sharer/sharer.php?u=southafrica.net/za/en/";
        this.twitter = "https://twitter.com/intent/tweet?text=" + window.location.href + " -";
        this.linkedin = "http://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
        this.email = "mailto:?subject=South African Tourism: " + this.title + "&body=" + this.overview + " - " + window.location.href;
        //this.mapInit();

      });

    //alert(dealId);
    //this.getDealById(dealId);



    //Setup map
  }
  bookNow(url:string){
    const gtmTag = {
      //'user_id': '3483878392',
      'deal_name': this.product.title + '/' + this.product.entry_id,
      'city': this.product.cf_destinations[0].mx_city,
      'province': this.province,
      'price': this.price,
      'event': 'book_now'
    };
    this.dataLayer.pushTag(gtmTag);
    //console.log(this.dataLayer.getDataLayer())
    window.open(url, '_blank');
  }
  changePhoto(url: string){
     this.mainPhoto = url;
  }
  getDealById(id: string): void {

  }
  mapInit() {
    this.infoWindow = new google.maps.InfoWindow();
   /* this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);*/
    /*var svgMarker = {
      path:
        "M16.4999 26.1392C21.9839 26.1392 26.4295 21.7302 26.4295 16.2915C26.4295 10.8528 21.9839 6.44391 16.4999 6.44391C11.0159 6.44391 6.57031 10.8528 6.57031 16.2915C6.57031 21.7302 11.0159 26.1392 16.4999 26.1392Z" +
        "M28.1675 4.79266C25.0509 1.70186 20.9077 0 16.5 0C12.0923 0 7.94906 1.70186 4.83254 4.79266C1.71602 7.88346 0 11.9932 0 16.3638C0 24.0753 5.43588 30.7324 12.9878 32.3548L16.5 37L20.0129 32.3548C27.5641 30.7324 33 24.0753 33 16.3638C33 11.9932 31.284 7.88346 28.1675 4.79266ZM16.5 4.27234C23.1826 4.27234 28.6192 9.66408 28.6192 16.2916C28.6192 22.919 23.1826 28.3108 16.5 28.3108C9.81737 28.3108 4.38076 22.919 4.38076 16.2916C4.38076 9.66408 9.81737 4.27234 16.5 4.27234Z",
      fillColor: "#FFCC00",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 1,
      anchor: new google.maps.Point(0, 0),
      PointerEvent: new PointerEvent('mouseover', {})
    };*/

    //const tempMarker = new google.maps.Marker({ position: this.locations[0], map: this.map , icon : svgMarker });
      /*tempMarker.addListener('click', ((tempMarker, map, infoWindow) => {
        return () => {

          var counting = tempMarker.getTitle().split('n')

          infoWindow.setContent(this.markerContent[Number(counting[1])].content);
          infoWindow.open(map, tempMarker);

          var activeButtons = document.getElementsByClassName('highlight');
          if(activeButtons.length > 0){
            this.renderer.removeClass(activeButtons[0],'highlight');
          }
          this.renderer.addClass(document.getElementById(tempMarker.getTitle()),'highlight');
          var idnumber = Number(counting[1]);

          var container = document.getElementById('tabcontent');
          var scrollTo = document.getElementById('btn'+idnumber.toString());
          container.scrollTop = scrollTo.offsetTop;
          //this.scroll(document.getElementById('btn'+idnumber.toString()));
        }
      })(tempMarker, this.map, this.infoWindow));
      this.mapMarkers.push(tempMarker);

    if(this.product?.cf_address_line_1 !== "" && this.product?.cf_address_line_1 !== null){
      this.map.setCenter(new google.maps.LatLng(Number(this.product?.cf_address_line_1), Number(this.product?.cf_address_line_2)));
    }
    else{
      this.map.setCenter(new google.maps.LatLng(Number(this.product?.cf_destinations[0]?.mx_latitude), Number(this.product?.cf_destinations[0]?.mx_longitude)));
    }
    this.map.setZoom(10);*/

    const apiKey = environment.googleMapsAPI;
    this.mapCenter = Number(this.product?.cf_destinations[0]?.mx_latitude) +',' + Number(this.product?.cf_destinations[0]?.mx_longitude);
    
    const zoom = 12; // Zoom level
    const mapType = 'roadmap'; // Type of map

    this.mapSize = '330x210'; 
    //this.imageUrl = "http://shotleft.co.za/assets/images/maps/mapIcon.svg";
    this.imageUrl = "icon:https://shotleft.co.za/assets/images/maps/mapIcon.png";
    this.staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${this.mapSize}&maptype=${mapType}&markers=${this.imageUrl}|${this.mapCenter}&key=${apiKey}`;
  }
}
