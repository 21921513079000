// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-info-map',
  templateUrl: './info-map.component.html',
  styleUrls: ['./info-map.component.scss']
})
export class InfoMapComponent implements OnInit, OnDestroy {
  public title: string;
  public ProvinceText : string;
  public HistoryText : string;
  public Provincestatus: string;
  public imgsrc: any;
  public background: any;

  @ViewChild('target') target;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: CmsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    this.title = this.data.getAllByRegion("1")[0].title;
    var random = this.randomizer(1, 9);
    this.randomProvince(random);
    this.background = (`url(${this.imgsrc})`);
    this.ProvinceText = "It’s Your Country. Enjoy it!";
    this.HistoryText = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
  }

  
  public ngOnDestroy() : void {
   
  }
  goToProvince(){
    var active = document.getElementsByClassName("activeProvince");
    sessionStorage.clear();

    if(active.length > 0){
      sessionStorage.setItem('provinceTo', active[0].id);
      sessionStorage.setItem('provinceFrom', 'Select province');
      window.location.href = "/province/"+active[0].id.replace(' ', '-').toLowerCase();
    }
    else{
      sessionStorage.setItem('provinceTo', 'Select province');
      sessionStorage.setItem('provinceFrom', 'Select province');
      window.location.href = "/province/mzansi";
    }

    
    
  }
  OnClick (event: any, province)
  {

    //console.log(event.path[2]);
   
    // // event.path[2].childNodes.forEach(element => {
    // //      this.renderer.removeClass(element.childNodes[0], 'activeProvince');
    // // });

    var active = document.getElementsByClassName("activeProvince");

    for (let index = 0; index < active.length; index++) {
      this.renderer.removeClass(active[index],'activeProvince');
    }

    this.renderer.addClass(event.target, 'activeProvince');
    

    switch(province) { 
      case 'Northern Cape': { 
        this.ProvinceText = province; 
        this.HistoryText = "When it comes to things to do, places to see and people to meet, this diverse province is like the seven colours of South African provinces. You can go from sandy beaches, to star gazing, to bush veld all in one day. So pack your bags and dala you must."; 
        var random = this.randomizer(1, 16);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'Western Cape': { 
        this.ProvinceText = province; 
        this.HistoryText = "Crystal clear rivers that water the banks of some of the finest winelands in the world. Iconic mountains that spill into oceans. A rich mixture of heritage and innovation. Awe ma se kind, this is the Western Cape."; 
        var random = this.randomizer(2, 12);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
        }

        this.background = (`url(${this.imgsrc})`);
         break; 
      } 
      case 'North West': { 
        this.ProvinceText = province; 
        this.HistoryText = "Want to know where your ancestors took their first steps? Head to North West and say le kae to the locals who will happily help uncover and discover, this province that boarders Botswana, and is a stone’s chuck (throw) away from neighbouring Limpopo."; 
        var random = this.randomizer(1, 10);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);

        break; 
      } 
      case 'Free State': { 
        this.ProvinceText = province; 
        this.HistoryText = "The heartland of Mzansi. For those looking to swap the big lights for the countryside and get an escape from city living. Laid back farmlands, some of the most historical sites in South Africa and a unique getaway. He banna, what are you waiting for? Come visit the Free State!"; 
        var random = this.randomizer(1, 13);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS' + random + '.jpg';
        }

        this.background = (`url(${this.imgsrc})`); 
        break; 
      } 
      case 'Gauteng': { 
        this.ProvinceText = province; 
        this.HistoryText = "Ola! This is Egoli, the province of stars. Home to Mzansi’s biggest cities and biggest businesses. From vibey nightlife and mega shopping complexes to natural springs and wilderness retreats. You can literally find almost anything here, zwagala!"; 
        var random = this.randomizer(1, 9);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'Mpumalanga': { 
        this.ProvinceText = province; 
        this.HistoryText = "Africa’s land of the rising sun and where one of nguni’s romantic tongues is spoken. Mpumalanga is a place where nature, nebantfu, nurture each other in a naturally harmonious way of living."; 
        var random = this.randomizer(1, 11);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`); 
        break; 
      } 
      case 'Limpopo': { 
        this.ProvinceText = province; 
        this.HistoryText = "Dintshang! Are you ready to hit the road and come over to the province that carries the spirit of Mzansi within it? Whether it’s our famous wildlife on show or our majestic plant life and scenery, Limpopo is the province for those seeking an adventurous yet traditional South African outdoors experience. Come over to the wild side.";
        var random = this.randomizer(1, 10);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'Kwazulu Natal': { 
        this.ProvinceText = 'KwaZulu Natal'; 
        this.HistoryText = "Egagasini, a province where the waves meet the land, and where culture meets heritage, means having a good time in a forgiving tropical climate. Woza eZulu land uzozibonela."; 
        var random = this.randomizer(1, 8);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
         break; 
      } 
      case 'Eastern Cape': { 
        this.ProvinceText = province; 
        this.HistoryText = "Sbwl this, Sbwl that! Hayi don’t do that to yourself. Pack a bag and head eastwards to eMpuma-Koloni. A province where you’ll find yourself kicking off your city shoes to take a stroll with nature on natural beach’s and coast lines, that have been nurtured by locals."; 
        var random = this.randomizer(1, 12);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
 
        break; 
      } 
      default: { 
        this.ProvinceText = "It’s Your Country. Enjoy it!"; 
        this.HistoryText = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
        var random = this.randomizer(1, 9);
        this.randomProvince(random);
        this.background = (`url(${this.imgsrc})`);
        break;
      } 
   } 
   
  }

  randomizer(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  randomProvince(province) {
    switch (province) {
      case 1:
        this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + province + '.jpg';
        break;
      case 2:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
      case 3:
        this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + province + '.jpg';
        break;
      case 4:
        this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + province + '.jpg';
        break;
      case 5:
        this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + province + '.jpg';
        break;
      case 6:
        this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + province + '.jpg';
        break;
      case 7:
        this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + province + '.jpg';
        break;
      case 8:
        this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + province + '.jpg';
        break;
      case 9:
        this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + province + '.jpg';
        break;
      default:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
    }
  }
}
