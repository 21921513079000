// Angular imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BannersModel } from 'src/app/models/main/deals/banners.model';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';


// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';
//declare var gtag;
@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePage implements OnInit {
   // site text variables for localization
  bannersArray: BehaviorSubject<BannersModel[] | undefined> = new BehaviorSubject<BannersModel[] | undefined>(undefined);
  dealsData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  Search: SearchModel = new SearchModel();
  constructor(
    private http: HttpClient,
    private data: DealsService,
    private gtmService: GoogleTagManagerService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    //private router: Router
    )
    {
      // const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd),);
      // navEndEvents.subscribe((event : NavigationEnd) =>
      // {
      //   gtag('config', 'GTM-N3VDTB3' , {
      //     'page_path' : event.urlAfterRedirects
      //   });
      // });
  }

  public ngOnInit() : void {
    //TODO something
    if(this.bannersArray === null)
        this.bannersArray.next(this.data.getAllBanners());

      this.getBanners();

    this.getAllFilterLive(this.Search);

  }
  getBanners(){

    this.bannersArray.next(null);
    this.bannersArray.next(this.data.getAllBanners());
  }

  getAllFilterLive(search : SearchModel): void{

    this.activatedRoute.data.subscribe(response => {
       var data = response.dealsSingle;
       if(data != undefined){
        this.dealsData.next(data.
          filter(result =>
          result.status == "open" &&
          (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
          new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
          (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
          (result.cf_package_type === search.packageType || search.packageType === "") &&
          (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
          (result.cf_campaign === search.campaign || search.campaign === "") &&
          (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
          ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
          //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
          (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
          ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
          ))
          //console.log(this.dealsData)
       }

    });

  }
}
