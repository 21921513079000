 <small-info-map [SearchFilter]="searchFilter"></small-info-map>
<!-- [ProvinceName] = "provname" [ProvinceDescription] = "prodesc" -->


<section class="space-ptb" style="padding-bottom:20px;background-color: #f4f4f4;">
    <div class="container" style="margin-left:0px;max-width:100%;padding-left:0px;">
        <section>
            <div class="container" style="max-width:90%;">
              <div class="row">
                <div class="col-12 text-center">
                  <p style="font-size: 25px;" class="text-black mb-4 mb-md-5"> 
                    {{ProvinceDescription}}
                    </p>
                </div>
              </div>
            </div>
        </section>
        <div style="position: relative;">
          
          <div class="row justify-content-center" >
            
            <div *ngFor="let exp of topExperiences; let i = index" [attr.data-index]="i" style="margin-left:30px;margin-right:30px;">  
              
              <div class="feature-info-03 mb-lg-0 mb-4 text-center">
                <div class="feature-icon">
                  <img class="imground" style="width:110px" src="{{exp.image}}"/>
                </div>
                <div class="feature-content">
                  <h5 class="feature-title mb-3">{{exp.name}}</h5>
                  
                </div>
                
                
                
              
            </div>
            
          </div>
          
          </div>
          <div class="vertical">&nbsp;&nbsp;&nbsp;Popular in region&nbsp;&nbsp;&nbsp;</div>
        </div>
        
      
    </div>    
</section>

  <app-deals-map [FilterData]="searchModel" [dealsData]="dealsDataFull" (provinceChange)="getNewProvince($event)"></app-deals-map>
  <div class="row"></div>
  <section class="bg-white">  
  <!-- <app-provinces-banner></app-provinces-banner> -->
      <div class="row justify-content-center" id="scrollmap" style="margin-right:0px;margin-left:0px;position:relative;margin-top:110px;">
        <div class="mb-4 text-center">
          <nav aria-label="breadcrumb ">
            <ol class="breadcrumb" style="font-size: 16px;">
            
              <li style="margin-right:auto;" class="breadcrumb-item"><a href="/itinerary"><img style="width:14px;margin-top:0px;margin-right:20px;" src="/assets/images/left-arrow.png"> Get out there</a></li>
            </ol>
          </nav>
        </div>
      </div>

  </section>
  
    <app-featured-deals-small [FilterData]="searchModel" [dealsData]="dealsDataSingle"></app-featured-deals-small>

    <!-- <app-banner-adds [bannersArray]="bannersArray"></app-banner-adds> -->
