// angular imports
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';



import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NewTravelWeekModel } from '../models/main/travelweek/newtravelweek.model';

@Injectable({ providedIn: 'root' })


export class TravelWeekService {

    env = environment;
    TravelWeekApiUrl = this.env.apiTravelWeekURL;
    
    httpOptions = {
        headers: new HttpHeaders({
        })
    };

    constructor(
        private http: HttpClient
        ) { }


    public AddTravelWeekUser(application: NewTravelWeekModel): Observable<NewTravelWeekModel> {
      return this.http.post<NewTravelWeekModel>(this.TravelWeekApiUrl, application, {observe: 'response'})
        .pipe(
          map(res => {
            if (res) {
              //debugger;
              if (res.status == 200) {
                return new NewTravelWeekModel().deserialize(res.body)
              }
            }
            return new NewTravelWeekModel().deserialize(res.body);
          })
        );
    }
}
