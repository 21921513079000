import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DealsService } from 'src/app/services/deals.service';
import { data } from 'src/app/services/product.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
public activities: any[] = [];
public stays: any[] = [];
closeResult = '';
constructor(
  private modalService: NgbModal,
  private dataNew: DealsService,
  private dataLayer: GoogleTagManagerService,
  ) {
  }

public ngOnInit() : void {
  this.activities = [];
  this.stays = [];

  var button = document.getElementById('modal_button');
  
  if(this.getCookie('cookies') != 'true')
        button.click();

  this.stays = this.dataNew.getAllPackageTypesFooter();
  this.activities = this.dataNew.getAllExperiencesFooter();

  this.stays.sort(this.compareTypes);
  this.activities.sort(this.compareTypes);
}
show(content: any){
  this.modalService.open(content);
}
setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
  acceptCookies(){
    sessionStorage.setItem('cookies', 'true');
    this.setCookie('cookies', 'true', 7);
    this.modalService.dismissAll();
  
  }
  socialMedia(url, platform){
    const gtmTag = {
      'platform': platform, 
      'event': 'social_share'
    };
    this.dataLayer.pushTag(gtmTag);
    //console.log(this.dataLayer.getDataLayer())
    window.open(url, '_blank');
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  } 
  compareTypes(a, b) {
    const cityA = a.name.toUpperCase();
    const cityB = b.name.toUpperCase();
    let comparison = 0;
    if (cityA > cityB) {
      comparison = 1;
    } else if (cityA < cityB) {
      comparison = -1;
    }
    return comparison;
  }
  goToProvince(province: string){
    
    sessionStorage.clear();
    sessionStorage.setItem('provinceTo', province);
    sessionStorage.setItem('activity', province);
    sessionStorage.setItem('provinceFrom', 'Select province');
   
    window.location.href = "/province/"+province.replace(' ', '-').toLowerCase();
  }

  goToActivity(activity: string){
    sessionStorage.clear();
    sessionStorage.setItem('provinceTo', "Select province");
    sessionStorage.setItem('type', activity);
    window.location.href = "/province/south-africa";
  }

  goToStay(stay: string){
    sessionStorage.clear();
    sessionStorage.setItem('provinceTo', "Select province");
    sessionStorage.setItem('theme', stay);
    window.location.href = "/province/south-africa";
  }
}
