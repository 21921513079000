<section class="page-title page-title-bottom bg-holder bg-overlay-black-50" style="padding-top: 20%;background: #000000;background-image: url(assets/images/10reasons-bg.png);">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 text-center">
        <h2 class="text-shotleft" >TOP REASONS </h2>
        <h3 class="text-white mb-4 mb-md-5">to travel in South Africa</h3>        <nav aria-label="breadcrumb">
        </nav>
      </div>
    </div>
  </div>
</section>

<section class="space-ptb" style="margin-top:25px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mt-lg-0 mt-5" *ngFor="let ten of TenReasons ; let i = index" [attr.data-index]="i">
        <div class="mt-4">
          <h5 class="mb-4">{{ten.reason}}</h5>
          <div class="blog-post-description">
            <p class="mb-0">{{ten.reasondescription}}</p>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="owl-carousel " data-nav-dots="true" data-items="2" data-md-items="2" data-sm-items="1"
                data-xs-items="1" data-xx-items="1" data-space="15">
                <div class="item" *ngFor="let art of ten.articles ; let x = index" [attr.data-index]="x">
                  <div class="blog-post bg-overlay-half-bottom bg-holder"
                    style="background-image: url({{art.image}});">
                    <div class="blog-post-info">
                      <h5 class="blog-post-title"><a target="_blank" href="{{art.url}}" style=" background: black; ">&nbsp;{{art.title}}&nbsp;</a></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>