
export class SearchModel {
    constructor(
        public packageType: string = "",
        public price: string = "",
        public searchTerm: string = "", //wildcard search from title
        public province: string = "",
        public city: string = "",
        public starGrading: string = "",
        public experienceType: string = "",
        public discount: string = "",
        public campaign: string = "",
        public deal_ids: string = ""
    )   
    {
    }
}

