// angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Model Imports
import { DataModel } from '../models/main/cms/data.model';


// Dummy data import
import campaignData from '../../assets/dummy-data/campaign-data.json';
import travelweekData from '../../assets/dummy-data/travelweek-data.json';

import { CampaignModel } from '../models/main/campaign/campaign.model';
import { TravelWeekModel } from '../models/main/travelweek/travelweek.model';
export const data = campaignData;

@Injectable({ providedIn: 'root' })


export class CampaignService {
    public data = campaignData;
    public travelweekData = travelweekData;

    constructor(
        private http: HttpClient
        ) { }
    //TODO add service calls here
    getAllCampaign(): CampaignModel[]{
        const filteredData = this.data.filter(result => result);
        return filteredData;
    }
    getAllCampaignById(id: string): CampaignModel[]{
        const filteredData = this.data.filter(result => result.id == id);
        return filteredData;
    }

    //TravelWeek
    getTravelWeekById(id: string): TravelWeekModel[]{
        const filteredData = this.travelweekData.filter(result => result.deal_Id === id);
        return filteredData;
    }
}
