<section class="page-title bg-holder bg-overlay-black-50" style="background-image: url(assets/images/provincemap/NW/SAT_IMGS_NW05.jpg);">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 text-center">
        <h1 class="ContactUstext">Contact Us</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="/contactus">Contact-Us</a></li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
<!--=================================
Page title -->

<!--=================================
Contact -->
<section class="space-pb mt-n5 position-relative z-index-1">
  <div class="container">
    <div class="row no-gutters bg-white shadow">
      <div class="col-lg-7 bg-white">
        <div class="contact-form p-md-5 p-4">
          <h4 *ngIf="!Busy && !Success" class="mb-4 text-primary">Let’s Get In Touch!</h4>
          <form class="pt-3">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary" role="status" *ngIf="Busy">
                <span class="sr-only">Loading...</span>
              </div>
              <div *ngIf="Success">
                <h4 class="mb-4 text-primary">Thank you ! We will get in touch soon.</h4>
              </div>
              <div *ngIf="Required">
                <h4 class="mb-4 text-primary">Error. Please fill in all required fields.</h4>
              </div>
              <div *ngIf="Failed">
                <h4 class="mb-4 text-primary">Error. Please try again.</h4>
              </div>
            </div>
            <div class="form-row" *ngIf="!Busy && !Success">
              <div class="form-group col-md-6">
                <label>Name</label>
                <input [(ngModel)]="ContactUs.firstName" #ContactUs.firstName="ngModel" name="ContactUs.firstName" type="text" class="form-control" placeholder="Your name">
              </div>

              <div class="form-group col-md-6">
                <label>Surname</label>
                <input [(ngModel)]="ContactUs.lastName" #ContactUs.lastName="ngModel" name="ContactUs.lastName" type="text" class="form-control" placeholder="Your surname">
              </div>
              
              <div class="form-group col-md-6">
                <label>Email</label>
                <input  pattern="[^ @]*@[^ @]*" [(ngModel)]="ContactUs.emailAddress" #ContactUs.emailAddress="ngModel" name="ContactUs.emailAddress" type="email" class="form-control" placeholder="Your email">
              </div>
              <div class="form-group col-md-12">
                <label>Message</label>
                <textarea [(ngModel)]="ContactUs.message" #ContactUs.emailAddress="ngModel" name="ContactUs.message" class="form-control" rows="4" placeholder="Your message"></textarea>
              </div>
              <!-- <div class="form-group col-md-12">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label pr-5" for="customCheck1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</label>
                </div>
              </div> -->
              <div class="col-md-12">
                <a class="btn btn-primary" (click)="AddContactUs()">Send message</a>
              </div>

            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5 contactusbg p-md-5 p-4">
        <h4 class="ContactUstext mb-4">Contact information</h4>
        <div class="contact-address pt-3">
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-location ContactUstext fa-3x"></i>
            </div>
            <div class="ml-3">
              <h6 class="ContactUstext">Address</h6>
              <p class="ContactUstext">Bojanala House, 90 Protea Road, Chislehurston, Johannesburg, 2196</p>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-mail ContactUstext fa-3x"></i>
            </div>
            <div class="ml-3">
              <h6 class="ContactUstext">Email</h6>
              <p class="ContactUstext">shotleft@southafrica.net</p>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-call ContactUstext fa-3x"></i>
            </div>
            <div class="ml-3">
              <h6 class="ContactUstext">Phone Number</h6>
              <a class="ContactUstext mb-2" href="tel:(011) 895-3000">+27(0)11 895 3000</a>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-call ContactUstext fa-3x"></i>
            </div>
            <div class="ml-3">
              <h6 class="ContactUstext">Fax</h6>
              <a class="ContactUstext mb-2" href="tel:(011) 086-8747">+27(0)11 895-3001</a>
            </div>
          </div>
          <div class="social-icon-02 mt-4 mt-md-5">
            <div class="d-flex align-items-center">
              <h6 class="mr-3 mb-0 ContactUstext">Social :</h6>
              <ul class="list-unstyled mb-0 d-flex">
                <li><a href="https://www.facebook.com/shotleft" class="icons mr-3"> <i class="fab fa-facebook-f"></i> </a></li>
                <li><a href="https://x.com/shotleft" class="icons mr-3"> <i class="fa-brands fa-x-twitter"></i> </a></li>
                <!-- <li><a href="#" class="icons mr-3"> <i class="fab fa-linkedin"></i> </a></li>-->
                <li><a href="https://www.youtube.com/user/shotleft" class="icons mr-3"> <i class="fab fa-youtube"></i> </a></li> 
                <li><a href="https://www.instagram.com/shotleft/" class="icons mr-3"> <i class="fab fa-instagram"></i> </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--=================================
Contact -->

<!--=================================
Contact Info -->
<!-- <section class="space-pb">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h4 class="mb-4 mb-sm-5">Additional contact Info</h4>
      </div>
      <div class="col-md-4">
        <div class="">
          <i class="flaticon-destination text-primary fa-4x"></i>
          <div class="px-3">
            <h5>Lorem ipsum dolor</h5>
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-md-0">
        <div class="">
          <i class="flaticon-pin text-primary fa-4x"></i>
          <div class="px-3">
            <h5>Lorem ipsum dolo</h5>
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-md-0">
        <div class="">
          <i class="flaticon-chat-1 text-primary fa-4x"></i>
          <div class="px-3">
            <h5>Lorem ipsum dolor</h5>
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--=================================
Contact Info -->

<!--=================================
Map -->
<section class="map">
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="map h-500">
          <!-- iframe START -->
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.460080529287!2d28.048514365028236!3d-26.116537883476862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950d552d4f9dc5%3A0x2f4e62d1e3e04d89!2sBojanala%20House!5e0!3m2!1sen!2sza!4v1624521223500!5m2!1sen!2sza"  style="border:0; width: 100%; height: 500px;" allowfullscreen="" loading="lazy"></iframe>          <!-- iframe END -->
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Map -->



<!-- Global site tag (gtag.js) - Google Analytics -->
<!--<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-N3VDTB3');</script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push({
      'event': 'page_view',
      'user_id': '',
      'page_title': 'Contact-Us',
      'page_path': '/',
      'page_category': 'Landing Pages'
    });
  }
  gtag("js", new Date());

  gtag("config", "UA-XXXXXX");
</script>-->