// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';

@Component({
  selector: 'travelweek-banner',
  templateUrl: './travelweek-banner.component.html',
  styleUrls: ['./travelweek-banner.component.scss']
})
export class TravelWeekBannerComponent implements OnInit, OnDestroy {
  public title: string;
  public ProvinceText : string;
  public ProvinceDescription : string;
  public ProvinceUrlLink: string;
  public imgsrc: any;
  public background: any;
  public ProvinceVideoLink: string;
  public innerWidth: any;

  @Input() ProvinceName: string;
  @Input() FilterData: InfomapModel;
  public filterData: InfomapModel = new InfomapModel();

  @ViewChild('target') target;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: CmsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    setTimeout(() => { }, 500);

    //debugger;
    this.setMapData(this.FilterData);
    
    this.ProvinceName = this.filterData.ProvinceText;
    this.ProvinceDescription = this.filterData.ProvinceDescription;
    this.filterData.ProvinceUrlLink = this.filterData.ProvinceUrlLink;    
    
    this.innerWidth = window.innerWidth;
    var random = this.randomizer(1, 9);
    this.randomProvince(random);

    document.getElementById("travelweekbanner").setAttribute("style", "background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(0 255 122)), url("+this.imgsrc+");");
    
    if(this.innerWidth > 700){
      // document.getElementById("travelweekbanner").setAttribute("data-jarallax-video", "https://www.youtube.com/watch?v=QHCYm4i7kFU&ab_channel=Sho%27tLeft");
      document.getElementById("hidemobile").setAttribute("class", "col-lg-1 mb-5 text-left");
      document.getElementById("hidemobile").setAttribute("style", "position: relative;"); 
    }else{
      //var random = this.randomizer(1, 9);
    //this.randomProvince(random);
    //document.getElementById("travelweekbanner").setAttribute("style", "background-image: url("+this.imgsrc+");");
    document.getElementById("hidemobile").setAttribute("class", "col-lg-0 text-left");
    document.getElementById("hidemobile").setAttribute("style", "position: relative;margin-bottom: 100%;");
    
    }

  }
  
  randomizer(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  
  public ngOnDestroy() : void {
   
  }
  
  randomProvince(province) {
    switch (province) {
      case 1:
        this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + province + '.jpg';
        break;
      case 2:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
      case 3:
        this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + province + '.jpg';
        break;
      case 4:
        this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + province + '.jpg';
        break;
      case 5:
        this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + province + '.jpg';
        break;
      case 6:
        this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + province + '.jpg';
        break;
      case 7:
        this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + province + '.jpg';
        break;
      case 8:
        this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + province + '.jpg';
        break;
      case 9:
        this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + province + '.jpg';
        break;
      default:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
    }
  }
  public setMapData(mapData: any) : void {
    this.filterData.imgName = mapData.imgName;
    this.filterData.ProvinceDescription = mapData.ProvinceDescription;
    this.filterData.ProvinceText = mapData.ProvinceText;
    this.filterData.ProvinceUrlLink = mapData.ProvinceUrlLink;

    //this.setProvinceActive(mapData.ProvinceText);
  }

  public setProvinceActive(id: string){
     var el = document.getElementById(id);
     this.renderer.addClass(el, "activeProvince");
  }
}
