// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { DealsService } from 'src/app/services/deals.service';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { BannersModel } from 'src/app/models/main/deals/banners.model';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  public background: any;
  public imgsrc: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2,
    
  ) {}

  public ngOnInit() : void {
    this.imgsrc = 'assets/images/Chintsa-beach.png';
    this.background = (`url(${this.imgsrc})`);
  }
  public ngOnDestroy() : void {
   
  }
}
  
