import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public activeClass: any;

  constructor(
    private renderer:Renderer2
    ) {
    }

  public ngOnInit() : void {}


  changeActiveBtn(event: any)
  {
    var active = document.getElementsByClassName("activebtnTab");

    for (let index = 0; index < active.length; index++) {
      this.renderer.removeClass(active[index],'activebtnTab');
    }

    this.renderer.addClass(event.target, 'activebtnTab');
  }  
}
