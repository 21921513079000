<campaign-banner [FilterData]="filterData"></campaign-banner>

<section class="space-ptb">
    <div class="container">
        <section>
            <div class="container">
              <div class="row">
                <div class="col-12 text-center">
                  <p style="font-size: 25px;" class="text-black mb-4 mb-md-5">{{Camp[0].short_description}}</p>
                </div>
              </div>
            </div>
        </section>

        
      <section>
        <div class="container mt-4">
          <div class="row">
            <div class="col-12 text-center">
              <p style="font-size: 18px;" class="text-black mb-4 mb-md-5">{{Camp[0].long_description}}</p>
            </div>
          </div>
        </div>
    </section>
    </div>    
</section>

  <app-deals-map-campaign [FilterData]="searchModel"></app-deals-map-campaign>
      
      <div class="row justify-content-center">
        <div class="mb-4 text-center">
          <h1 class="text-white">IT'S YOUR COUNTRY</h1>
          <p class="text-white banner-sub-title">Take a sho't left</p>
          <nav aria-label="breadcrumb ">
            <ol class="breadcrumb" style="font-size: 2.3vmin;">
            
              <li style="margin-right:auto;" class="breadcrumb-item"><a href="/itinerary"><img style="width:14px;margin-top:0px;margin-right:20px;" src="/assets/images/left-arrow.png"> Get out there</a></li>
            </ol>
          </nav>
        </div>
      </div>


    <app-featured-deals-small [FilterData]="searchModel"></app-featured-deals-small>

    <!-- <app-banner-adds [bannersArray]="bannersArray"></app-banner-adds> -->
