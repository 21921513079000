import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AppTokenService {
    private storage = sessionStorage;
    private token = "token";

    public clear() : void {
        this.storage.removeItem(this.token);
    }

    public any() : boolean {
        return this.get() !== null;
    }

    public get() : string | null {
        return this.storage.getItem(this.token);
    }

    public set(token: string) : void {
        this.storage.setItem(this.token, token);
    }
}
