<!--<section class="" style="background-image: url(assets/images/Chintsa-beach.png);background-size: cover;background-position: center center;background-repeat: no-repeat;">
  <div class="row">
    <div class="col-lg-8">
      <div class="form-group">
        <div class="col-md-8">
          <h2 class="text-white mt-4">
            <a style="text-decoration: underline; -webkit-text-decoration-color: #FDCA13; /* Safari */ text-decoration-color: #FDCA13; text-underline-position: under;" >Zwakala, Tloho</a>, come over!</h2>

          <p class="text-white mb-4 mb-md-5">
            Ekse my friend, the country’s open and waiting for you to travel and discover all the amazing things that it has to offer.
            <br/> <br/>
            What are you looking for? Because Mzansi has it all. Beautiful people and beautiful places. From majestic rivers to sun-soaked beaches, from breath-taking mountains to valleys and views that will leave you speechless. From gritty wildlife to vibey nightlife there’s literally nothing you can’t experience. So, get out there, take a trip and have some fun.        
          </p>
        </div>
      </div>

    </div>
    
    <div class="col-lg-4 text-left">
      <iframe style="margin-top: 5%;width: 90%;margin-bottom: 5%;height: 80%;" src="https://www.youtube.com/embed/qglyqwLIoik" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
    </div>

  </div>  
</section>-->

<section class="space-ptb bg-holder bg-overlay-black-50 bg-image-shotleft" [style.backgroundImage]='background'>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h2 class="text-white mt-4">
          <a style="text-decoration: underline; -webkit-text-decoration-color: #FDCA13; /* Safari */ text-decoration-color: #FDCA13; text-underline-position: under;" >It's Your Country, </a> Enjoy It!
        </h2>

        <p class="text-white" style="text-align: justify;">Heita Mzansi! Welcome to Sho't Left, your gateway to the stunning natural beauty and cultural wonders of Mzansi! Whether you're a seasoned traveler or a curious explorer, we invite you to discover the hidden gems that make Mzansi a world-class destination.
          <br /> <br />
          From the rugged mountains and rolling savannas to the pristine beaches and bustling cities, Mzansi is a country of unparalleled diversity and breathtaking landscapes. Take a Sho't Left with us and explore the untold stories and unspoiled treasures that await you in every corner of our beautiful land.
          <br /><br />
          From the majestic Drakensberg Mountains to the lush Winelands, the Big Five game reserves to the vibrant cityscapes of Jozi, Durban, and Cape Town, there is no shortage of incredible places to explore in Mzansi.
          <br /><br />
          Zwakala! Get in touch with partners and book your Sho't Left today and get ready to experience the trip of a lifetime!
          <br /><br />
        </p>
      </div>
      <div class="col-lg-5 mt-0">
      
       <!--<iframe style="margin-top: 5%;width: 90%;margin-bottom: 5%;height: 80%;" src="https://www.youtube.com/embed/iu57X3TIdbg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->   


      </div>
    </div>
  </div>
</section>