// angular imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Model Imports
import { DealsNewModel } from '../models/main/product/deals.model';

// Dummy data import
import productData from '../../assets/dummy-data/product-data.json';
export const data = productData;

@Injectable({ providedIn: 'root' })


export class ProductService {
    public data = productData;
    
    constructor(
        private http: HttpClient
        ) { }
    //TODO add service calls here
    getAllProductsById(id: string): DealsNewModel[]{
        const filteredData = this.data.filter(result => result.id === id);
        return filteredData;
    }

    getAllProducts(): DealsNewModel[]{
        const filteredData = this.data.filter(result => result);
        return filteredData;
    }
}
