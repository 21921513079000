

  <header class="header">
    <div *ngIf="loading" class="loader">
    </div>
    <div *ngIf="!loading">
      <app-header></app-header>
    </div>
  </header >
  <!--<app-login></app-login>-->
  
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  

    

