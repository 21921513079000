import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs/operators';
//Services

//Global
//import dealsDashboardData from '../assets/dummy-data/deals-dashboard.json';

//export const dealsText = dealsDashboardData;
//export const dealsTest = dealsService.getAllDeals();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private gtmService: GoogleTagManagerService,
  ) {}

  public ngOnInit() : void {
    var page_title = '';
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const routeTitle = this.getChild(this.activatedRoute);
        routeTitle.data.subscribe((data: { title: string }) => {
          this.titleService.setTitle(data.title + ' | Shotleft');
          page_title = data.title;
        });
      });

      this.router.events.forEach(item => {

        if (item instanceof NavigationEnd) {
          var items = item.url.replace('/', '').split('/')
          const gtmTag = {
            event: 'page_view',
            //user_id: '95787384',
            page_title:  page_title,
            page_path: item.url,
            page_category: 'Landing Pages'
          };
          this.gtmService.pushTag(gtmTag);
          //console.log(this.gtmService.getDataLayer());
        }
      });

      //console.log(this.dataLayer);
  }

  public getChild(activatedRoute: ActivatedRoute) : any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
