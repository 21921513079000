<section *ngIf="locationsArray.length > 0" class="space-pb popup-gallery overflowx-h" style="position:relative;padding-top:30px;background-color:#F6F6F6;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2>Recommended Sho't Left</h2>
          <div> 
            <span style="background-color:#F6F6F6;"> Heita! So, you want to get out there and you’re looking for something great within your budget. Here are some of the hottest deals for stays and activities to suit the kind of experience you’re looking for. It’s your country, Enjoy it!
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <owl-carousel-o [options]="customOptions" >
          <ng-container *ngFor="let loc of locationsArray ; let i = index">
            <ng-template carouselSlide [id]="">
            <div class="location-item-02 bg-holder text-center"
              style="background-image: url({{loc.cf_images[0]?.mx_image}});border-top-left-radius:6%;border-top-right-radius:6%;height:150px;">
              <div *ngIf="loc.cf_discount != '0'" class="SpecialdiscountSmall">&nbsp;&nbsp;&nbsp;&nbsp;{{loc.cf_discount}}% Off&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div class="listing-image" style=" margin-left: 60%;">
                <!-- <div class="listing-quick-box-small">
                  <a class="like" style="margin-left:50px;cursor:pointer;" data-toggle="tooltip" data-placement="top"
                    title="Like"> <i class="fa fa-plus-circle"></i> </a>
                </div> -->
                <a class="iconsyellow social-share" data-toggle="tooltip" data-placement="top" title="Zoom"> <i class="fas fa-code-branch"></i>
                  <div class="social-box">
                    <a target="_blank" href="{{facebook}}"><i class="fab fa-facebook-f">&nbsp;</i></a>
                    &nbsp;&nbsp;
                    <a target="_blank" href="{{twitter}}{{loc.title}}"><i class="fa-brands fa-x-twitter" >&nbsp;</i></a>                                   
                    
                    &nbsp;&nbsp;

                    <!-- <a target="_blank" href="{{linkedin}}"><i class="fab fa-linkedin-in"></i>&nbsp;</a> -->
                    <a target="_blank" href="mailto:?subject=South African Tourism:  {{loc.title}} &body= {{loc.cf_deal_description}} {{break}} {{email}}"><i class="fas fa-envelope"></i>&nbsp;</a>

                     <!-- <li class="socialmedia"><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                     <li class="socialmedia"><a href="#"><i class="fab fa-instagram"></i></a></li> -->
                 </div> 
                </a>
                <!-- <a class="like" style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="Like"> <i class="fas fa-heart"></i> </a>  -->

                <!-- <div class="listing-quick-box-custom-small">
                  <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="food"> <i
                      class="fas fa-utensils"></i> </a>
                  <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="plane">
                    <i class="fas fa-plane"></i> </a>
                  <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="bus"> <i
                      class="fas fa-bus"></i> </a>
                  <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="hotel">
                    <i class="fas fa-hotel"></i> </a>
                </div> -->
              </div>
            </div>
            <div class="listing-item">
              <div class="listing-details">
                <div class="listing-details-inner-small">
                  <div class="listing-title">
                    <h6><a>{{loc.title.substring(0,50)}}</a></h6>
                  </div>
                  <div class="listing-info">
                    <div class="mb-0">{{loc.cf_deal_summary.substring(0,200)}}</div>
                  </div>
                  <br />
                </div>
                <div class="listing-bottom">
                  <div class="col-6 text-left listing-shotleft">
                    <a class="listing-price">ZAR {{loc.cf_price}}</a>
                  </div>
                  <div class="col-6 text-right listing-shotright">
                    <a (click)="goToDeals(loc.url_title)" class="listing-open">Explore more ></a>
                  </div>
                </div>
              </div>
            </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>