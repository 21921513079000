<section class="page-title bg-holder bg-overlay-black-50" id="travelweekbanner" style="background-image: url('assets/images/provincemap/WC/SAT_IMGS_WC07.jpg');padding:0 0 0 0;min-height:50%;max-height:50%;height: 50%!important;">
  <div class="container banner-image-container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="d-flex align-items-center">
          
          <div id="hidemobile" style="position:relative;">
            <img id="CampaignIcon" src="assets/images/travelweek/lockup.png" style=" width: 40%;">     
          </div>           
          
          
        </div>
      </div>
      
    </div>
  </div>
</section>