<section class="page-title bg-holder bg-overlay-black-50" id="travelweekbanner">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="d-flex align-items-center">
          
          <div id="hidemobile" class="col-lg-1 mb-5 text-left" style="position:relative;">
            <img id="CampaignIcon" src="assets/images/travelweek/Campaign-Icon.png" style=" width: 90%;">     
          </div>           
          <div class="col-lg-8 mt-5 text-left" style="position:relative;">
             <h1 class="text-white mt-4" style=" font-weight: bolder; ">COMING SOON</h1>
             <h4 class="text-white mb-0" style=" font-weight: bolder; ">TRAVEL WEEK <a class="yellowText">6 - 12 SEPTEMBER</a></h4>

                  <span class="text-white"></span>


                  <br/>
                  <br/>

                  <br/>
                  <h4 class="text-white mb-2" style=" font-weight: bolder; ">THE GREAT <a class="yellowText">SOUTH AFRICAN</a> SALE</h4>

                  <p class="text-white mb-2" style="font-weight: 100;font-size: 14px;">Get up 50% off travel week deals. Coming Soon. Save the date <a style="font-weight: 500;" >6-12 September</a></p>

                
            </div>
          
        </div>
      </div>
      <div class="col-lg-4 text-center mt-3 mt-md-0">
          
      </div>
    </div>
  </div>
</section>