// Angular imports
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Bootstrap Imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Core imports
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AppErrorHandler } from './core/handlers/error.handler';
import { AppHttpInterceptor } from './core/interceptors/http.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Page Imports
import { TestPage } from './pages/main/test-page/test-page.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { MainModule } from './components/main/main.module';
import { LoginComponent } from './layouts/login/login.component';
import { LightLayoutComponent } from './layouts/light-layout/light-layout.component';
import { HomePage } from './pages/main/home-page/home-page.component';
import { ProvincePage } from './pages/main/province-page/province-page.component';
import { ItineraryPage } from './pages/main/itinerary-page/itinerary-page.component';
import { TradePartnerAccomPage } from './pages/main/tradepartneraccom-page/tradepartneraccom-page.component';
import { DarkLayoutComponent } from './layouts/dark-layout/dark-layout.component';
import { BannersModel } from './models/main/deals/banners.model';
import { CampaignPage } from './pages/main/campaign-page/campaign-page.component';
import { CampaignComponent } from './components/main/campaign-banner/campaign-banner.component';
// import { TravelWeekPage } from './pages/main/travelweek-page/travelweek-page.component';
import { TravelWeekBannerComponent } from './components/main/travelweek-banner/travelweek-banner.component';
import { ContactUsPage } from './pages/main/contact-us/contact-us.component';

import { TravelWeekPage } from './pages/main/travelweek/travelweek.component';

import { TenReasonsPage } from './pages/main/ten-reasons page/ten-reasons.component';
import { AboutComponent } from './components/main/about/about.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ScrollBar } from '@ng-bootstrap/ng-bootstrap/util/scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { TravelWeeknewPage } from './pages/main/travelweeknew-page/travelweeknew-page.component';
import { CountdownModule } from 'ngx-countdown';
import { EasterSeasonPageComponent } from './pages/main/easter-season-page/easter-season-page.component';



@NgModule({
  declarations: [
    AppComponent,
    TestPage,
    HomePage,
    MainLayoutComponent,
    LightLayoutComponent,
    DarkLayoutComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    ProvincePage,
    ItineraryPage,
    TradePartnerAccomPage,
    CampaignComponent,
    CampaignPage,
    // TravelWeekPage,
    TravelWeekBannerComponent,
    ContactUsPage,
    TravelWeekPage,
    TenReasonsPage,
    AboutComponent,
    // TravelWeeknewPage,
    EasterSeasonPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    MainModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    GoogleTagManagerModule,
    SlickCarouselModule,
    NgScrollbarModule,
    ScrollingModule,
    CountdownModule
  ],
  exports: [
   HeaderComponent
  ],
  providers: [
    Title,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }, // fix for 404 errors
    { provide: 'googleTagManagerId', useValue: 'GTM-N3VDTB3'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
