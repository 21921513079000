// Angular imports
import { Component, OnInit, setTestabilityGetter } from '@angular/core';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { BannersModel } from 'src/app/models/main/deals/banners.model';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DealsModel } from 'src/app/models/main/deals/deals.model';

// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'province-page',
  templateUrl: './province-page.component.html',
  styleUrls: ['./province-page.component.scss']
})

export class ProvincePage implements OnInit {
  public id: string;
  public imgsrc: any;
  public background: any;
  public FilterData: InfomapModel = new InfomapModel();
  public filterData: InfomapModel = new InfomapModel();
  public searchFilter: BehaviorSubject<InfomapModel | undefined> = new BehaviorSubject<InfomapModel | undefined>(undefined);
  public Search: SearchModel = new SearchModel("","","","","","","");
  public searchModel: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);
  public provinceChange: string;
  public popularexperiences: DealsModel[];
  public dealsData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  public dealsDataFull: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  public dealsDataSingle: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);

  public popularexperiencesCount: any[] = [];
  public popularexperiencesfCount: any[] = [];
  public topExperiences: any[] = [];
  public ProvinceDescription : string;
  public datas: any[];

  provname = "Northern Cape";
  prodesc = "Northern Cape Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod";
  bannersArray: BehaviorSubject<BannersModel[] | undefined> = new BehaviorSubject<BannersModel[] | undefined>(undefined);
  
  constructor(
    private data: DealsService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private http: HttpClient
    ) {
  }

  public ngOnInit() : void {
    
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
      //alert(this.id);
    });
    
    
    //let pget = sessionStorage.getItem('provinceTo');
    let pget = this.id;
    let type = sessionStorage.getItem('type');
    let theme = sessionStorage.getItem('theme');
    let city = sessionStorage.getItem('cityTo');
    let price = "";
    //alert(type);alert(theme);

    if(pget === null || pget === "")
        location.href = "/";
    //TODO: Default South Africa
    pget = (pget.trim() == 'mzansi') ? 'south-africa' : pget.trim();
    let province = pget.trim();
    //alert(province);
    
    this.imgsrc = 'assets/images/province/laughing.png';
    this.background = (`url(${this.imgsrc}) no-repeat`);

    this.filterData.imgName = 'hut'; //TODO: Change to Video per province.
    this.filterData.ProvinceUrlLink = "https://www.youtube.com/watch?v=LgvseYYhqU0";
    this.filterData.ProvinceText = province.toString(); 
    //TODO: Section/PRovince description
    this.filterData.ProvinceDescription = province + ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod';
    
    this.FilterData = this.filterData;
    this.searchFilter.next(this.FilterData);
    
    //POPULAR EXPERIENCES PER PROVINCE
    var prov = province.replace(" ","-").toLocaleLowerCase();
    this.getProvinceText(prov);
    const experiences = this.data.getAllExperiences();
    this.getAllFilterLive(this.Search);
    if(province === 'south-africa')
    {
      //alert(province);
      //this.popularexperiences  = this.data.getAllFilter(this.Search);
      //this.getAllFilterLive(this.Search);
      
    }
    else  
    {
      
      //this.popularexperiences  = this.data.getAllByProvince(prov);
      this.getAllByProvince(prov);
      
    }

    //console.log(this.popularexperiences);

    

    //POPULAR EXPERIENCES PER PROVINCE
    //TODO: Add type to filter;
    this.Search.packageType = theme;
    this.Search.province = pget;
    this.Search.city = city;
    this.Search.experienceType = type;
    this.Search.price = price;

    if(this.bannersArray === null)
        this.bannersArray.next(this.data.getAllBanners());

    this.searchModel.next(this.Search);
      
    this.getBanners();
    //console.log(this.titleService.getTitle());
    let setTitle = province.split('-');
    let new_title = province.charAt(0).toUpperCase()+province.substr(1)

    if(setTitle.length > 1)
    new_title = setTitle[0].charAt(0).toUpperCase()+setTitle[0].substr(1)+' '+setTitle[1].charAt(0).toUpperCase()+setTitle[1].substr(1);

    this.titleService.setTitle("Province | " + new_title);
  }
  getBanners(){
    
    this.bannersArray.next(null);
    this.bannersArray.next(this.data.getAllBanners());
  }
  getNewProvince(province: any){
       this.provinceChange = province;
  }
  isInArray(value, array) {
    return array.indexOf(value) > -1;
  }
  getProvinceText(province)
  {
    //alert(province);
    switch(province) { 
      case 'northern-cape': { 
        this.ProvinceDescription = "When it comes to things to do, places to see and people to meet, this diverse province is like the seven colours of South African provinces. You can go from sandy beaches, to star gazing, to bush veld all in one day. So pack your bags and dala you must."; 
        break; 
      } 
      case 'western-cape': { 
        this.ProvinceDescription = "Crystal clear rivers that water the banks of some of the finest winelands in the world. Iconic mountains that spill into oceans. A rich mixture of heritage and innovation. Awe ma se kind, this is the Western Cape."; 
         break; 
      } 
      case 'north-west': { 
        this.ProvinceDescription = "Want to know where your ancestors, ancestor’s took their first steps? Head to North West and say le kae to the locals who will happily help uncover and discover, this province that boarders Botswana, and is a stone’s chuck (throw) away from neighbouring Limpopo."; 
         break; 
      } 
      case 'free-state': { 
        this.ProvinceDescription = "The heartland of Mzansi. For those looking to swap the big lights for the countryside and get an escape from city living. Laid back farmlands, some of the most historical sites in South Africa and a unique getaway. He banna, what are you waiting for? Come visit the Free State!"; 
         break; 
      } 
      case 'gauteng': { 
        this.ProvinceDescription = "Ola! This is Egoli, the province of stars. Home to Mzansi’s biggest cities and biggest businesses. From vibey nightlife and mega shopping complexes to natural springs and wilderness retreats. You can literally find almost anything here, zwagala!"; 
         break; 
      } 
      case 'mpumalanga': { 
        this.ProvinceDescription = "Africa’s land of the rising sun and where one of nguni’s romantic tongues is spoken. Mpumalanga is a place where nature, nebantfu, nurture each other in a naturally harmonious way of living."; 
         break; 
      } 
      case 'limpopo': { 
        this.ProvinceDescription = "Dintshang! Are you ready to hit the road and come over to the province that carries the spirit of Mzansi within it? Whether it’s our famous wildlife on show or our majestic plant life and scenery, Limpopo is the province for those seeking an adventurous yet traditional South African outdoors experience. Come over to the wild side."; 
         break; 
      } 
      case 'kwazulu-natal': { 
        this.ProvinceDescription = "Egagasini, a province where the waves meet the land, and where culture meets heritage, meets having a good time in a forgiving tropical climate. Woza eZulu land uzozibonela."; 
         break; 
      } 
      case 'eastern-cape': { 
        this.ProvinceDescription = "Sbwl this, Sbwl that! Hayi don’t do that to yourself. Pack a bag and head eastwards to eMpuma-Koloni. A province where you’ll find yourself kicking off your city shoes to take a stroll with nature on natural beach’s and cost lines, that have been nurtured by locals."; 
         break; 
      } 
      case 'south-africa':  { 
        this.ProvinceDescription = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
        break; 
      } 
      default: { 
        this.ProvinceDescription = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
        break; 
      } 
   } 
  }
  getAllFilterLive(search : SearchModel): void{
    const experiences = this.data.getAllExperiences();
    var data2 : any[] = [];
    
    this.activatedRoute.data.subscribe(response => {
       var data = response.deals;
       
       if(data != undefined){
        this.dealsData.next(data.
          filter(result => 
          result.status == "open" &&
          (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
          new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
          (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
          (result.cf_package_type === search.packageType || search.packageType === "") &&
          (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
          (result.cf_campaign === search.campaign || search.campaign === "") &&
          (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
          ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
          //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
          (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
          ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
          ));
          this.activatedRoute.data.subscribe(response => {
            data2 = response.dealsSingle;
            data2 = data2.filter(result => 
                    result.status == "open" &&
                    (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
                    new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
                    (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
                    (result.cf_package_type === search.packageType || search.packageType === "") &&
                    (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
                    (result.cf_campaign === search.campaign || search.campaign === "") &&
                    (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
                    ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
                    //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
                    (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
                    ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != ""));
                    });

          this.dealsDataFull.next(data);
          this.dealsDataSingle.next(data2);
          this.dealsData.subscribe(result => { 
            this.popularexperiences = result; 
            for (let index = 0; index < this.popularexperiences.length; index++) {
             for (let i = 0; i < experiences.length; i++) {
               var count = 0;
               count = this.popularexperiences.filter(x=>x.cf_experience_type === experiences[i].id).length;
               if(count > 0)
               {
                   this.popularexperiencesCount.push({
                     experience: experiences[i].id,
                     image: experiences[i].image,
                     name: experiences[i].name,
                     Count: count,
                   });  
               }
             }
           }
       
           this.popularexperiencesfCount = this.popularexperiencesCount.filter((v,i) => this.popularexperiencesCount.findIndex(item => item.experience == v.experience) === i);
           const top = this.popularexperiencesfCount.sort((x, y) => y - x).slice(0, 4);
           this.topExperiences = top.sort((a, b) => parseFloat(b.Count) - parseFloat(a.Count));
           }
         
       );
       }
       
    }); 
    
  }
  getAllByProvince(province : string): void{
    const experiences = this.data.getAllExperiences(); 
    var data2 : any[] = [];
    this.activatedRoute.data.subscribe(response => {
        data2 = response.dealsSingle;
    });
    this.activatedRoute.data.subscribe(response => {
      var data = response.deals;
      
       if(data != undefined){
        this.dealsData.next(data.
          filter(result => 
            result.status == "open" &&
            new Date(Number(result.expiration_date)*1000) >= new Date() && //Expiry date
            (result.cf_destinations[0]?.mx_province === province.toLocaleLowerCase() || province == "")));
            
            data2.
          filter(result => 
            result.status == "open" &&
            new Date(Number(result.expiration_date)*1000) >= new Date() && //Expiry date
            (result.cf_destinations[0]?.mx_province === province.toLocaleLowerCase() || province == ""));
            
            this.dealsDataFull.next(data);
            this.dealsDataSingle.next(data2);

            this.dealsData.subscribe(result => {
              this.popularexperiences = result;
              //console.log(result);
              for (let index = 0; index < this.popularexperiences.length; index++) {
                for (let i = 0; i < experiences.length; i++) {
                  var count = 0;
                  count = this.popularexperiences.filter(x=>x.cf_experience_type === experiences[i].id).length;
                  if(count > 0)
                  {
                      this.popularexperiencesCount.push({
                        experience: experiences[i].id,
                        image: experiences[i].image,
                        name: experiences[i].name,
                        Count: count,
                      });  
                  }
                }
              }
          
              this.popularexperiencesfCount = this.popularexperiencesCount.filter((v,i) => this.popularexperiencesCount.findIndex(item => item.experience == v.experience) === i);
              //let maxObj = this.popularexperiencesfCount.reduce((max, obj) => (max.Count > obj.Count) ? max : obj);
              const top = this.popularexperiencesfCount.sort((x, y) => y - x).slice(0, 4);
              this.topExperiences = top.sort((a, b) => parseFloat(b.Count) - parseFloat(a.Count));
              //console.log(this.topExperiences);
            });
       }
       
    }); 
}
  
}

