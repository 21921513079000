// Angular imports
import { Component, OnInit } from '@angular/core';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { CampaignModel } from 'src/app/models/main/campaign/campaign.model';
import { BannersModel } from 'src/app/models/main/deals/banners.model';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { CampaignService } from 'src/app/services/campaign.service';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router';

// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss']
})

export class CampaignPage implements OnInit {
  public id: string;
  public imgsrc: any;
  public background: any;
  public FilterData: InfomapModel = new InfomapModel();
  public filterData: InfomapModel = new InfomapModel();
  public Search: SearchModel = new SearchModel("","","","","","","");
  public Camp: CampaignModel[];
  public searchFilter: BehaviorSubject<InfomapModel | undefined> = new BehaviorSubject<InfomapModel | undefined>(undefined);
  public searchModel: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);

  provname = "Northern Cape";
  prodesc = "Northern Cape Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod";
  bannersArray: BehaviorSubject<BannersModel[] | undefined> = new BehaviorSubject<BannersModel[] | undefined>(undefined);
  
  constructor(
    private data: DealsService,
    private campaigndata: CampaignService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
    ) {
  }

  public ngOnInit() : void {

    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
      //alert(this.id);
    });
    //debugger;
    this.Camp = this.campaigndata.getAllCampaignById(this.id);
    sessionStorage.clear();
    let pget = 'Select province';
    let type = sessionStorage.getItem('type');
    let theme = sessionStorage.getItem('theme');
    let city = sessionStorage.getItem('cityTo');

    if(pget === null || pget === "")
        location.href = "/";

    let province = (pget.trim() == 'Select province') ? 'South Africa' : pget.trim();

    this.imgsrc = 'assets/images/province/laughing.png';
    this.background = (`url(${this.imgsrc}) no-repeat`);

    this.filterData.imgName = 'hut'; //TODO: Change to Video per province.
    this.filterData.ProvinceUrlLink = this.Camp[0].video_url;
    this.filterData.ProvinceText = this.Camp[0].title.toString(); 
    //TODO: Section/PRovince description
    this.filterData.ProvinceDescription = this.Camp[0].sub_title;
    
    this.FilterData = this.filterData;
    this.searchFilter.next(this.FilterData);
    
    
    //TODO: Add type to filter;
    this.Search.packageType = theme;
    this.Search.province = pget;
    this.Search.city = city;
    this.Search.experienceType = type;
    this.Search.deal_ids = this.Camp[0].deal_Ids;
    
    //alert(this.Camp[0].deal_Ids);

    if(this.bannersArray === null)
        this.bannersArray.next(this.data.getAllBanners());

    this.searchModel.next(this.Search);
      
    this.getBanners();

    this.titleService.setTitle("Campaign | " + this.Camp[0].title);

  }
  getBanners(){
    
    this.bannersArray.next(null);
    this.bannersArray.next(this.data.getAllBanners());
  }
}
