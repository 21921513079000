<owl-carousel-o id="side-owl" name="side-owl"
                      [options]="customOptions">
                    <ng-container *ngFor="let loc of slides ; let i = index">
                      <ng-template carouselSlide [id]="">
                      <div class="location-item-02 bg-holder bg-overlay-black-50 text-center" style="background-image: url({{loc.cf_images[0]?.mx_image}});" >
                        
                      </div>
                      <div class="listing-item">
                        <div class="listing-details">
                          <div class="listing-details-inner-small" style="min-height:100px;">
                            <div class="listing-title">
                              <h6>{{loc.cf_product.title.substring(0,20)}}</h6>
                            </div>
                            <div class="listing-info">
                              <h6 style="font-size:1.9vmin;">{{loc.title.substring(0,25)}}</h6>
                            </div>
                            <br />
                          </div>
                          <div class="listing-bottom">
                            <div class="col-6 text-left listing-shotleft" style="padding-top:0;">
                              <a href="/" class="listing-price">{{loc.cf_destinations[0].mx_city}}</a>
                            </div>
                            <div class="col-6 text-right listing-shotright" style="padding-top:0;">
                              <a (click)="goToDeals(loc.url_title)" class="listing-open">Learn more ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    </ng-container>
                    
                  </owl-carousel-o>