    <!-- <div>
      <app-deals-map></app-deals-map>
    </div>  -->


    <div>
      <small-info-map [FilterData]="FilterData"></small-info-map>
    </div> 

<!-- 
    <div>
      <itenary-planner></itenary-planner>

    </div> -->



