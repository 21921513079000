// Angular imports
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';


// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';

@Component({
  selector: 'itinerary-page',
  templateUrl: './itinerary-page.component.html',
  styleUrls: ['./itinerary-page.component.scss']
})

export class ItineraryPage implements OnInit {
  public imgsrc: any;
  public background: any;
  provname = "Northern Cape";
  prodesc = "Northern Cape Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod";
  public dealsDataFull: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);

  constructor(
    private data: DealsService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
    ) {
  }

  public ngOnInit() : void {
    this.imgsrc = 'assets/images/province/laughing.png';
    this.background = (`url(${this.imgsrc}) no-repeat`);
    this.getAllFilterLive();
  }
  
  getAllFilterLive(): void{
    const experiences = this.data.getAllExperiences();
    this.activatedRoute.data.subscribe(response => {
       var data = response.deals;
       if(data != undefined){
        
          this.dealsDataFull.next(data);
          
           }
         
       
       
    }); 
    
  }
}
