import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  public loading = false;
  constructor(private activatedRoute: ActivatedRoute) { }

  public ngOnInit() : void {
    this.loading = true; 
    
    
    setTimeout(function(){
      this.loading = false; 
    }.bind(this),1200);
  }

}
