// Angular imports
import { Component, OnInit } from '@angular/core';

// React Imports
import { BehaviorSubject } from 'rxjs';
import { BannersModel } from 'src/app/models/main/deals/banners.model';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DealsModel } from 'src/app/models/main/deals/deals.model';

// Global site text import
//import { dealsText } from '../../../app.component';
import { DealsService, dealsText } from '../../../services/deals.service';
import { CmsService } from 'src/app/services/cms.service';
import { ContactusModel } from 'src/app/models/main/contactus/contactus.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'contactus-page',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsPage implements OnInit {

  ContactUs: ContactusModel = new ContactusModel();
  Busy = false;
  Success = false;
  Failed = false;
  Required = false;
  NewApplicationContactusModel: ContactusModel = new ContactusModel();

  constructor(
    private data: CmsService,
    private activatedRoute: ActivatedRoute,
    private dataLayer: GoogleTagManagerService,
    ) {
  }

  public ngOnInit() : void {

  }

  AddContactUs() {

    const gtmTag = {
      'event': 'contact-us'
    };
    this.dataLayer.pushTag(gtmTag);

    this.Busy = true;
    this.ContactUs.country = "za";
    this.ContactUs.language = "en";
    this.ContactUs.section = "travel";
    if (this.ContactUs.firstName == undefined || this.ContactUs.lastName == undefined || this.ContactUs.emailAddress == undefined || this.ContactUs.message == undefined) {
      this.Required = true;
      this.Busy = false;
    }
    else {
      this.Required = false;
      this.Busy = true;
      this.ContactUs.country = "za";
      this.ContactUs.language = "en";
      this.ContactUs.section = "travel";

      this.data.AddContactUs(this.ContactUs).subscribe(data => this.NewApplicationContactusModel = data,
        (err) => {
          //console.log('Application Failed: ' + JSON.stringify(err.error));
          this.Busy = false;
          this.Success = false;
          this.Failed = true;
        },
        () => {
          //console.log('Application Success');
          this.Busy = false;
          this.Success = true;
          this.Failed = false;
          this.Required = false;
          this.NewApplicationContactusModel = this.NewApplicationContactusModel;
        }
      );
    }
}
}
