// Angular Import
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
//import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';

@Component({
  selector: 'small-info-map',
  templateUrl: './small-info-map.component.html',
  styleUrls: ['./small-info-map.component.scss']
})
export class SmallInfoMapComponent implements OnInit, OnDestroy {
  public title: string;
  public ProvinceText : string;
  public ProvinceDescription : string;
  public ProvinceUrlLink: string;
  public imgsrc: any;
  public background: any;
  public ProvinceVideoLink: string;
  public innerWidth: any;
  public HistoryText: string;

  @Input() ProvinceName: string;
  @Input() SearchFilter: BehaviorSubject<InfomapModel | undefined> = new BehaviorSubject<InfomapModel | undefined>(undefined);
  @Input() FilterData: InfomapModel;
  public filterData: InfomapModel = new InfomapModel();

  @ViewChild('target') target;

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: CmsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    
    setTimeout(() => { }, 500);
    this.innerWidth = window.innerWidth;
    //alert(this.innerWidth);
    //console.log(this.FilterData);
    this.SearchFilter.subscribe(result => {
      this.FilterData = result;
    });
    this.setMapData(this.FilterData);
    
    //this.imgsrc = 'assets/images/bg/'+this.filterData.imgName+'.svg';
    this.background = (`url(${this.imgsrc})`);
    //this.ProvinceUrlLink = this.filterData.ProvinceUrlLink;
    
    //this.ProvinceName = this.filterData.ProvinceText;
    //this.ProvinceDescription = this.filterData.ProvinceDescription;
    this.ProvinceVideoLink = this.ProvinceVideoLink;
    

    //alert(this.ProvinceVideoLink);
    //jarallax(document.querySelectorAll('.jarallax'));
  }

  
  public ngOnDestroy() : void {
   
  }
  
  public setMapData(mapData: any) : void {
    
    this.filterData.imgName = mapData.imgName;
    this.filterData.ProvinceDescription = mapData.ProvinceDescription;
    this.filterData.ProvinceText = mapData.ProvinceText;
    //this.filterData.ProvinceUrlLink = mapData.ProvinceUrlLink;
    this.setProvinceActive(mapData.ProvinceText.replace('-',' '));

    this.innerWidth = window.innerWidth;
    this.getBackgroundVideo(mapData.ProvinceText.replace('-',' '));
    //alert(this.innerWidth);
    this.getBanner(mapData.ProvinceText.replace('-',' '));
      document.getElementById("smallinfomapbanner").setAttribute("style", "background-image: url("+this.imgsrc+")");

      if(this.innerWidth > 700){
        document.getElementById("notmobileprovince").setAttribute("style", "display:block");
        document.getElementById("mobileprovince").setAttribute("style", "display:none");
      }else{
        document.getElementById("notmobileprovince").setAttribute("style", "display:none");
        document.getElementById("mobileprovince").setAttribute("style", "display:block");
      }

    
  }
  public getBackgroundVideo(province)
  {
    //alert(province);
    province = province.toLowerCase();
    //debugger;
    var url = 'https://www.youtube.com/watch?v=';
    switch(province) { 
      case 'northern cape': { 
        this.ProvinceVideoLink = url + 'SJhjuwwa1WM&ab_channel=SouthAfricanTourism'; 
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'SJhjuwwa1WM';
        break; 
      } 
      case 'western cape': { 
        this.ProvinceVideoLink = url + 'qbkGAp0Ot0A&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'qbkGAp0Ot0A';
         break; 
      } 
      case 'north west': { 
        this.ProvinceVideoLink = url + 'utDFf0CW9j8&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'utDFf0CW9j8';
         break; 
      } 
      case 'free state': { 
        this.ProvinceVideoLink = url + 'uJPaXPx3CdQ&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'uJPaXPx3CdQ';
         break; 
      } 
      case 'gauteng': { 
        this.ProvinceVideoLink = url + 'OcukaxrlC1Y&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'OcukaxrlC1Y';
         break; 
      } 
      case 'mpumalanga': { 
        this.ProvinceVideoLink = url + '_c7_3n8AOwg&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + '_c7_3n8AOwg';
         break; 
      } 
      case 'limpopo': { 
        this.ProvinceVideoLink = url + 'WLOuL7rTKbA&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'WLOuL7rTKbA';
         break; 
      } 
      case 'kwazulu natal': { 
        this.ProvinceVideoLink = url + '7zOnYrenEdA&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + '7zOnYrenEdA';
         break; 
      } 
      case 'eastern cape': { 
        this.ProvinceVideoLink = url + 'jVYa-UgisPo&ab_channel=SouthAfricanTourism';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'jVYa-UgisPo';
         break; 
      } 
      case 'south africa': { 
        this.ProvinceVideoLink = url + 'Lf82GWDgqMw';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'Lf82GWDgqMw';
         break; 
      } 
      default: { 
        this.ProvinceVideoLink = url + 'qLf82GWDgqMw';
        //document.getElementById("smallinfomapbanner").setAttribute("data-jarallax-video", this.ProvinceVideoLink);
        this.ProvinceUrlLink = url + 'Lf82GWDgqMw';
         break; 
      } 
   } 
   this.getProvinceText(province);
   
  }
  public setProvinceActive(id: string){
    //alert(id);
    if(id !== "south africa"){
      var el = document.getElementById(id);
      this.renderer.addClass(el, "activeProvince");
    }
     
  }
  getBanner(province: string): void{
    switch(province) { 
      case 'northern cape': { 
        var random = this.randomizer(1, 16);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'western cape': { 
        var random = this.randomizer(2, 12);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
        }

        this.background = (`url(${this.imgsrc})`);
         break; 
      } 
      case 'north west': { 
        var random = this.randomizer(1, 10);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);

        break; 
      } 
      case 'free state': { 
        var random = this.randomizer(1, 13);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS' + random + '.jpg';
        }

        this.background = (`url(${this.imgsrc})`); 
        break; 
      } 
      case 'gauteng': { 
        var random = this.randomizer(1, 9);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'mpumalanga': { 
        var random = this.randomizer(1, 11);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`); 
        break; 
      } 
      case 'limpopo': { 
        var random = this.randomizer(1, 10);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
        break; 
      } 
      case 'kwazulu natal': { 
        var random = this.randomizer(1, 8);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
         break; 
      } 
      case 'eastern cape': { 
        var random = this.randomizer(1, 12);

        if (random < 10) {
          this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + random + '.jpg';
        }
        else {
          this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC' + random + '.jpg';
        }
        this.background = (`url(${this.imgsrc})`);
 
        break; 
      } 
      default: { 
        var random = this.randomizer(1, 9);
        this.randomProvince(random);
        this.background = (`url(${this.imgsrc})`);
        break;
      } 
   } 
   province = province.toLowerCase();
   this.getProvinceText(province);
  
  }
  randomizer(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  randomProvince(province) {
    switch (province) {
      case 1:
        this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + province + '.jpg';
        break;
      case 2:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
      case 3:
        this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + province + '.jpg';
        break;
      case 4:
        this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + province + '.jpg';
        break;
      case 5:
        this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + province + '.jpg';
        break;
      case 6:
        this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + province + '.jpg';
        break;
      case 7:
        this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + province + '.jpg';
        break;
      case 8:
        this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + province + '.jpg';
        break;
      case 9:
        this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + province + '.jpg';
        break;
      default:
        this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + province + '.jpg';
        break;
    }
  }

  getProvinceText(province: string): void{
    switch(province) { 
      case 'northern cape': { 
        this.ProvinceName = "Northern Cape"; 
        this.ProvinceDescription = "When it comes to things to do, places to see and people to meet, this diverse province is like the seven colours of South African provinces. You can go from sandy beaches, to star gazing, to bush veld all in one day. So pack your bags and dala you must."; 
        break; 
      } 
      case 'western cape': { 
        this.ProvinceName = "Western Cape"; 
        this.ProvinceDescription = "Crystal clear rivers that water the banks of some of the finest winelands in the world. Iconic mountains that spill into oceans. A rich mixture of heritage and innovation. Awe ma se kind, this is the Western Cape."; 
         break; 
      } 
      case 'north west': { 
        this.ProvinceName = "North West"; 
        this.ProvinceDescription = "Want to know where your ancestors, ancestor’s took their first steps? Head to North West and say le kae to the locals who will happily help uncover and discover, this province that boarders Botswana, and is a stone’s chuck (throw) away from neighbouring Limpopo."; 
         break; 
      } 
      case 'free state': { 
        this.ProvinceName = "Free State"; 
        this.ProvinceDescription = "The heartland of Mzansi. For those looking to swap the big lights for the countryside and get an escape from city living. Laid back farmlands, some of the most historical sites in South Africa and a unique getaway. He banna, what are you waiting for? Come visit the Free State!"; 
         break; 
      } 
      case 'gauteng': { 
        this.ProvinceName = "Gauteng"; 
        this.ProvinceDescription = "Ola! This is Egoli, the province of stars. Home to Mzansi’s biggest cities and biggest businesses. From vibey nightlife and mega shopping complexes to natural springs and wilderness retreats. You can literally find almost anything here, zwagala!"; 
         break; 
      } 
      case 'mpumalanga': { 
        this.ProvinceName = "Mpumalanga"; 
        this.ProvinceDescription = "Africa’s land of the rising sun and where one of nguni’s romantic tongues is spoken. Mpumalanga is a place where nature, nebantfu, nurture each other in a naturally harmonious why of living."; 
         break; 
      } 
      case 'limpopo': { 
        this.ProvinceName = "Limpopo"; 
        this.ProvinceDescription = "Dintshang! Are you ready to hit the road and come over to the province that carries the spirit of Mzansi within it? Whether it’s our famous wildlife on show or our majestic plant life and scenery, Limpopo is the province for those seeking an adventurous yet traditional South African outdoors experience. Come over to the wild side."; 
         break; 
      } 
      case 'kwazulu natal': { 
        this.ProvinceName = "KwaZulu Natal"; 
        this.ProvinceDescription = "Egagasini, a province where the waves meet the land, and where culture meets heritage, meets having a good time in a forgiving tropical climate. Woza eZulu land uzozibonela."; 
          break; 
      } 
      case 'eastern cape': { 
        this.ProvinceName = "Eastern Cape"; 
        this.ProvinceDescription = "Sbwl this, Sbwl that! Hayi don’t do that to yourself. Pack a bag and head eastwards to eMpuma-Koloni. A province where you’ll find yourself kicking off your city shoes to take a stroll with nature on natural beach’s and cost lines, that have been nurtured by locals."; 
         break; 
      } 
      case 'south africa': { 
        this.ProvinceName = "It’s Your Country. Enjoy it!"; 
        this.ProvinceDescription = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
      
         break; 
      } 
      default: { 
        this.ProvinceName = "It’s Your Country. Enjoy it!"; 
        this.ProvinceDescription = "Majestic open spaces, sun-soaked coasts, bucket-list activities and the finest hidden gems waiting for you to discover them. Our provinces have everything and more. So, where do you want to go today?";
       
         break; 
      } 
   } 
  }

}
