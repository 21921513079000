// angular imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Model Imports
import { Deals, DealsModel } from '../models/main/deals/deals.model';
import { SearchModel } from '../models/main/deals/search.model';


// Dummy data import
//import dealsDashboardData from '../../assets/dummy-data/deals-dashboard.json';
import dealsDashboardData from '../../assets/dummy-data/deals-dashboard.json';
export const dealsText = dealsDashboardData;

import experiencesData from '../../assets/dummy-data/experiences.json';
export const experiences = experiencesData;

import bannersData from '../../assets/dummy-data/banners.json';

import packageTypesData from '../../assets/dummy-data/packagetypes.json';
import priceRangesData from '../../assets/dummy-data/pricerange.json';
import { ExperiencesModel } from '../models/main/deals/experiences.model';
import { PackagetypesModel } from '../models/main/deals/packagetypes.model';
import { BannersModel } from '../models/main/deals/banners.model';
import { DealsdashboardModel } from '../models/main/dealsdashboard/dealsdashboard.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
export const packageTypes = packageTypesData;

@Injectable({ providedIn: 'root' })


export class DealsService {
    public dealsData: DealsModel[];
    public data: any = dealsDashboardData;
    public experiences = experiencesData;
    public packagetypes = packageTypesData;
    public pricerange = priceRangesData;
    public banners = bannersData;
   // https://api-shotleft-staging.azurewebsites.net/latestdeal
   //https://api.shotleft.co.za/latestdeal
    //public url = 'https://api.shotleft.co.za/deal';
    //public urlsingle = 'https://api.shotleft.co.za/latestdeal';
    public url = 'https://tradeplatform-liveapi.azurewebsites.net/api/v1/Products/GetOpenDeals';
    public urlsingle = 'https://tradeplatform-liveapi.azurewebsites.net/api/v1/Products/GetOpenDeals';

    constructor(
        private http: HttpClient
        ) {

         }
x

    public ngOnInit() : void {

    }
    getDeals(): Observable<any[]> {
        return this.http.get<any[]>(this.url);
    }

    getDealsSingle(): Observable<DealsModel[]> {
        return this.http.get<any[]>(this.urlsingle);
    }

    GetAPIdata(url: string): void {

        let data: any;
        let promise = new Promise((resolve, reject) => {
            let apiURL = url;
            this.http.get(apiURL)
              .toPromise()
              .then(
                res => { // Success
                  this.data[0]   = res;
                  //console.log(res);
                }
              );
          });

    }
    GetReportsList(url: string): any[] {

        let apiData: any;
        this.GetAPIdata(url);

        //console.log(this.data);
        return apiData;
                //return this.http.get<any[]>(url, {observe: 'response'})
            //
                //  .pipe(
            //
                //    map(res => {
                //
                //      if (res) {
                //        //alert(res);
                //        //console.log(res);
                //        if (res.status == 200) {
                //        //console.log(res);
                //          return res.body.map(data => new Deals().deserialize(data))
            //
                //        }
            //
                //      }
                //      else{
                //          alert("Failed");
                //      }
                //      return res.body.map(data => new Deals().deserialize(data))
                //    })
            //
                //  );

      }
    public getApiDealsData(url: string): any{
        //this.http.get<DealsModel[]>(url,{headers: headers}).subscribe(results => {

                ///setTimeout(function(){
                    //this.data = results;
                  //},5000);
        //});
        var data: any;
        fetch(url, {
            method: 'GET'
         })
          .then(response => response.json())
          .then(json => { data = json })
          .catch(error => {
            //console.log('Fetch deals data failed : ' + error.message)
          });

        return data;

    }
    //TODO add service calls here
    getDealById(id: string): DealsModel {
        const filteredData = this.data.filter(result => result.status == "open" && result.url_title === id);
        return filteredData[0];
    }
    getAllByType(type: string): DealsModel[]{
        const filteredData = this.data.filter(result => result.status == "open" && result.cf_package_type === type && result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0 && !result.cf_images[0]?.mx_image.includes("("));
        return filteredData;
    }
    getAllByExperiences(id: string): DealsModel[]{
        const filteredData = this.data.filter(result => result.status == "open" && result.cf_experience_type === id && result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0 && !result.cf_images[0]?.mx_image.includes("("));
        return filteredData;
    }
    getAll(): DealsModel[]{

        const filteredData: any = this.data;
        return filteredData;
    }
    Getdealsd(): Observable<DealsdashboardModel>{
        return this.http.get<DealsdashboardModel[]>("https://dealsdashboard.hellosandbox.co.za/za/en/api/reno-deals?cf_country_code=za", {observe: 'response'})
          .pipe(
            map(res => {
                debugger;
              if (res) {
                if (res.status == 200) {
                    return new DealsdashboardModel().deserialize(res.body)
                }
              }
              return new DealsdashboardModel().deserialize(res.body);
            })
          );
      }
    getAllByDealsByIds(search : SearchModel) : DealsModel[]
    {
        let filteredData: any = this.data;

        if(search.deal_ids != null && search.deal_ids != ""){
            //alert("TEST")
            var split_ids = search.deal_ids.split(",");
            filteredData = [];

            if(split_ids.length > 0){
                for(let item of split_ids){

                    let new_entry = this.data.filter(result => result.entry_id === item);
                    //console.log(new_entry);
                    if(new_entry.length > 0){
                        filteredData.push(new_entry[0]);
                    }

                }

            }
        }


        //alert(split_ids.length);

        filteredData = filteredData.
        filter(result =>
        result.status == "open" &&
        new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
        new Date(Number(result.expiration_date)*1000) >= new Date() && //Expiry date
        (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
        (result.cf_package_type === search.packageType || search.packageType === "") &&
        (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
        (result.cf_campaign === search.campaign || search.campaign === "") &&
        (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
        (Number(result.cf_price) <= Number(search.price)  || search.price === "") &&
        //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
        ( search.province === "" || result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "south-africa") &&
        ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
        )

        //console.log(filteredData);

        return filteredData;
    }
    sleep(milliseconds): any[] {
        const date = Date.now();
        let currentDate = null;
        let dataProxy: any;
        while (currentDate - date < milliseconds){
            currentDate = Date.now();

        }
        this.http.get<any>("https://localhost:44353/deal").subscribe(data => {

            //console.log(data);
            dataProxy = data;
            //console.log(dataProxy);
        });

        //console.log(dataProxy);
        return dataProxy;
    }
    getAllFilter(search : SearchModel): DealsModel[]{

        //while(this.data.length <= 0){
            //this.GetReportsList("https://localhost:44353/deal").then((result) =>
            // {console.log(result); this.data = result;})
            //.catch(error => console.log(error));;

        //this.data = [];
        //console.log("Hello");
        //alert(this.sleep(6000));
        //console.log("World!");
        //console.log(this.data);


        let filteredData = this.data.
        filter(result =>
        (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
        new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
        (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
        (result.cf_package_type === search.packageType || search.packageType === "") &&
        (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
        (result.cf_campaign === search.campaign || search.campaign === "") &&
        (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
        ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
        //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
        (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
        ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")

        )

        //filteredData = Array.from(filteredData.reduce((m, t) => m.set(t.cf_destinations[0].mx_latitude && t.cf_destinations[0].mx_longitude, t), new Map()).values());

        //console.log(new Date(Number(filteredData[10].expiration_date)*1000))
        return filteredData;
    }
    getAllFilterLive(search : SearchModel): DealsModel[]{

        //while(this.data.length <= 0){
        //this.GetReportsList("https://localhost:44353/deal").then((result) =>
        // {console.log(result); this.data = result;})
        //.catch(error => console.log(error));

        this.data = [];
        //console.log("Hello");
        alert(this.sleep(6000));
        //console.log("World!");
        //console.log(this.data);


        let filteredData = this.data.
        filter(result =>
        (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
        new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
        (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
        (result.cf_package_type === search.packageType || search.packageType === "") &&
        (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
        (result.cf_campaign === search.campaign || search.campaign === "") &&
        (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
        ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
        //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
        (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
        ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
        )

        //console.log(new Date(Number(filteredData[10].expiration_date)*1000))
        return filteredData;
    }
    //Populate City Dropdownlist
    getAllCities(): DealsModel[]{
        const arr: any = [];
        const filteredData = this.data.filter(x=>x.cf_destinations[0]?.mx_city != "" && !x.cf_images[0]?.mx_image.includes("(")).reduce((a, o) => (o.cf_destinations != null && arr.push(o.cf_destinations), a));
        return arr;
    }
    //Populate City Dropdownlist
    //Populate City Dropdownlist by region
    getAllCitiesByRegion(region:string): any[]{
        const arr: any = [];
        const arr2: any = [];
        const filteredData = this.data.filter(x=>x.cf_destinations[0]?.mx_city != "" && x.cf_destinations[0]?.mx_province == region && !x.cf_images[0]?.mx_image.includes("(")).reduce((a, o) => (o.cf_destinations != null && arr.push(o.cf_destinations), a));

        const result = Array.from(arr.reduce((m, t) => m.set(t[0].mx_city, t), new Map()).values());
        return result;
    }
    getAllCitiesSingle(): any[]{
        const arr: any = [];
        const filteredData = this.data.filter(x=>x.cf_destinations[0]?.mx_city != "" && !x.cf_images[0]?.mx_image.includes("(")).reduce((a, o) => (o.cf_destinations != null && arr.push(o.cf_destinations), a));
        const result = Array.from(arr.reduce((m, t) => m.set(t[0]?.mx_city, t), new Map()).values());
        return result.sort();
    }
    getAllModesSingle(): any[]{
        const arr: any = [];
        const filteredData = this.data.filter(x=>x.cf_destinations[0]?.mx_city != "").reduce((m, t) =>( arr.push(t.cf_travel_mode), m));
        const result = Array.from(arr.reduce((m, t) => m.set(t, t), new Map()).values());
        return result.sort();
    }
    getAllExperiences(): any[] {
        return this.experiences;
    }
    getAllExperiencesFooter(): any[] {
        return this.experiences;
    }
    getAllPackageTypes(): any[] {
        const filteredData = this.packagetypes;
        return filteredData;
    }
    getAllPriceRanges(): any[] {
        const filteredData = this.pricerange;
        return filteredData;
    }
    getAllPackageTypesFooter(): any[] {
        const filteredData = this.packagetypes;
        return filteredData;
    }
    getAllBanners(): BannersModel[] {
        const filteredData = this.banners;
        return filteredData;
    }
    getAllByProvince(province : string): DealsModel[]{
        let filteredData = this.data.
        filter(result =>
        result.status == "open" &&
        new Date(Number(result.expiration_date)*1000) >= new Date() && //Expiry date
        (result.cf_destinations[0]?.mx_province === province.toLocaleLowerCase() || province == "")
        )
        return filteredData;
    }
    //Populate City Dropdownlist by region
}
