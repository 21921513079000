// Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LightLayoutComponent } from './layouts/light-layout/light-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

// Page Imports
import { HomePage } from './pages/main/home-page/home-page.component';
import { TestPage } from './pages/main/test-page/test-page.component';
import { ProvincePage } from './pages/main/province-page/province-page.component';
import { ItineraryPage } from './pages/main/itinerary-page/itinerary-page.component';
import { TradePartnerAccomPage } from './pages/main/tradepartneraccom-page/tradepartneraccom-page.component';
import { DarkLayoutComponent } from './layouts/dark-layout/dark-layout.component';
import { CampaignPage } from './pages/main/campaign-page/campaign-page.component';
import { ContactUsPage } from './pages/main/contact-us/contact-us.component';
import { TenReasonsModel } from './models/main/tenreasons/tenreasons.model';
import { TenReasonsPage } from './pages/main/ten-reasons page/ten-reasons.component';
import { DealsResolverService } from './services/deals-resolver.service';
import { DealssingleResolverService } from './services/dealssingle-resolver.service';
import { TravelWeeknewPage } from './pages/main/travelweeknew-page/travelweeknew-page.component';
import { EasterSeasonPageComponent } from './pages/main/easter-season-page/easter-season-page.component';
import { TravelWeekPage } from './pages/main/travelweek/travelweek.component';


const routes: Routes = [

    { path: '', component: MainLayoutComponent,
      children: [

        /* 01 */ { path: '', component: HomePage, data: { title: 'Home' },resolve: { dealsSingle:  DealssingleResolverService } },
      ]
    },
    {
      path: '', component: MainLayoutComponent,
      children: [
        
                 { path: 'travelweek', component: TravelWeekPage, data: { title: 'Travel-Week-2023' } },
        /* XX */ { path: 'test', component: TestPage, pathMatch: 'full', data: { title: 'Test' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        /* 02 */ { path: 'province/:id', component: ProvincePage, data: { title: 'Province' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        /* 03 */ { path: 'itinerary', component: ItineraryPage, data: { title: 'Itinerary planner' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        /* 04 */ { path: 'campaign/:id', component: CampaignPage, data: { title: 'Campaign' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
       // /* 05 */ { path: 'travelweek', component: TravelWeeknewPage, data: { title: 'Travel-Week-2022' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        /* 06 */ { path: 'contact-us', component: ContactUsPage, data: { title: 'Contact-Us Page' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        /* 07 */ { path: 'ten-reasons', component: TenReasonsPage, data: { title: 'Top Reasons Page' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
        // /* 08 */ { path: 'seat-at-the-table', component: EasterSeasonPageComponent, data: { title: 'Seat-At-The-Table' },resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService} },
       ],
    },
    {
      path: '', component: DarkLayoutComponent,
      children: [
          /* 04 */ {
                      path: 'deals/:id',
                      component: TradePartnerAccomPage,
                      data: { title: 'Deals' },
                      resolve: { deals: DealsResolverService, dealsSingle:  DealssingleResolverService}
                    },
       ],
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
