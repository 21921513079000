// angular imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Bootstrap Imports
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// components import
import { DealsMapComponent } from './deals-map/deals-map.component';
import { FeaturedDealsComponent } from './featured-deals/featured-deals.component';
import { InfoMapComponent } from './info-map/info-map.component';
import { BuildItineraryComponent } from './build-itinerary/build-itinerary.component';
import { ProvincesBannerComponent } from './provinces-banner/provinces-banner.component';
import { SmallInfoMapComponent } from './small-info-map/small-info-map.component';
import { BannerAddsComponent } from './banner-adds/banner-adds.component';
import { FeaturedDealsSmallComponent } from './featured-deals-small/featured-deals-small.component';
import { ItenaryPlannerComponent } from './itenary-planner/itenary-planner.component';
import { SidemenuCarouselComponent } from './sidemenu-carousel/sidemenu-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DealsMapCampaignComponent } from './deals-map-campaign/deals-map-campaign.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TravelWeekBannernewComponent } from './travelweek-bannernew/travelweek-bannernew.component';
import { EasterDealsComponent } from './easter-deals/easter-deals.component';
// import { Travelweek2022Component } from '../../pages/main/travelweek2022/travelweek2022/travelweek2022.component';
import { TravelWeeknewPage} from '../../pages/main/travelweeknew-page/travelweeknew-page.component';

@NgModule({
  providers: [NgbActiveModal],
  declarations: [
    DealsMapCampaignComponent,
    DealsMapComponent,
    InfoMapComponent,
    FeaturedDealsComponent,
    BuildItineraryComponent,
    ProvincesBannerComponent,
    SmallInfoMapComponent,
    FeaturedDealsSmallComponent,
    BannerAddsComponent,
    ItenaryPlannerComponent,
    SidemenuCarouselComponent,
    TravelWeekBannernewComponent,
    EasterDealsComponent,
    // Travelweek2022Component
    TravelWeeknewPage
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    NgScrollbarModule,
    ScrollingModule
  ],
  exports: [
    RouterModule,
    DealsMapCampaignComponent,
    DealsMapComponent,
    InfoMapComponent,
    FeaturedDealsComponent,
    BuildItineraryComponent,
    ProvincesBannerComponent,
    SmallInfoMapComponent,
    FeaturedDealsSmallComponent,
    BannerAddsComponent,
    ItenaryPlannerComponent,
    SidemenuCarouselComponent,
    TravelWeekBannernewComponent,
    EasterDealsComponent

  ]
})
export class MainModule {}
