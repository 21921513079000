// angular imports
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';

// Model Imports
import { DataModel } from '../models/main/cms/data.model';


// Dummy data import
import cmsData from '../../assets/dummy-data/cms-data.json';
import { environment } from 'src/environments/environment';
import { ContactusModel } from '../models/main/contactus/contactus.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
export const data = cmsData;

@Injectable({ providedIn: 'root' })


export class CmsService {
    public data = cmsData;
    env = environment;
    ContactApiUrl = this.env.apiURL + 'ContactApi/ContactUs';
    
    httpOptions = {
        headers: new HttpHeaders({
        })
    };

    constructor(
        private http: HttpClient
        ) { }
    //TODO add service calls here
    getAllByRegion(region: string): DataModel[]{
        const filteredData = this.data.filter(result => result.region === region);
        return filteredData;
    }


    public AddContactUs(application: ContactusModel): Observable<ContactusModel> {
      return this.http.post<ContactusModel>(this.ContactApiUrl, application, {observe: 'response'})
        .pipe(
          map(res => {
            if (res) {
              //debugger;
              if (res.status == 200) {
                return new ContactusModel().deserialize(res.body)
              }
            }
            return new ContactusModel().deserialize(res.body);
          })
        );
    }
}
