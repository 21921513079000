// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsService } from '../../../services/cms.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { DealsService } from 'src/app/services/deals.service';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-featured-deals',
  templateUrl: './featured-deals.component.html',
  styleUrls: ['./featured-deals.component.scss']
})
export class FeaturedDealsComponent implements OnInit, OnDestroy {
  public title: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  email : string;
  break : string;
  locationsArray: DealsModel[] = [];
  Search: SearchModel = new SearchModel();
  customOptions: OwlOptions;
  @Input() FilterData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);

  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    
    this.searchNew();
    this.facebook = "https://www.facebook.com/sharer/sharer.php?u=southafrica.net/za/en/";
    this.twitter = "https://twitter.com/intent/tweet?text=" + window.location.href + " " + " -";
    this.linkedin = "http://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
    this.email = window.location.href;
    this.break = "-"
  }

  
  search()
  {
    this.locationsArray = this.data.getAllFilter(this.Search);
    for (let i of this.locationsArray) {
      if(i.cf_images[0]?.mx_image.includes("(")){
        i.cf_images[0].mx_image = "assets/images/image.png";
      }
    }
  }
  searchNew()
  {
    this.FilterData.subscribe(result => {
      this.customOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoWidth:true,
        margin:20,
        dots: true,
        navSpeed: 700,
        navText: ['', ''],
        autoHeight: true,
        center: true,
        items: 1,
        responsive: {
          0: {
            items: 1
          },
          740: {
            items: 3
          },
          940: {
            items: 3
          },
          1024: {
            items: 3
          },
          1400: {
            items: 3 
          }
        },
        nav: false
      };

      if(result !== undefined){
        
        this.locationsArray = result.sort((x, y) => +new Date(Number(y.date_deal_approved)*1000) - +new Date(Number(x.date_deal_approved)*1000));
        
        if(this.locationsArray.length > 50){
            this.locationsArray = this.locationsArray.slice(0,50);
        }
        
        //alert(this.locationsArray.length);

        for (let i of this.locationsArray) {
          if(i.cf_images[0].mx_image.includes("(") && !i.cf_images[0].mx_image.includes("\\")){
            i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(/\(/g,'\\(').replace(/\)/g,'\\)');
          }
          else if(i.cf_images[0].mx_image === "") 
          {
            i.cf_images[0].mx_image = "assets/images/image.png";
          }
          if(i.cf_images[0].mx_image.includes(" ")) 
          {
            i.cf_images[0].mx_image = i.cf_images[0].mx_image.replace(' ','%20');
          }
      }
        //console.log(result);
      }
      
    });
    
    
  }
  goToDeals(deal: string){
    
    sessionStorage.setItem('dealId', deal);
    window.location.href = "/deals/"+deal.replace(' ', '-').toLowerCase();
    
  }
  public ngOnDestroy() : void {
   
  }
}
  
