<section class="space-pb space-ptb bg-holder bg-overlay-black-50 bg-image-shotleft" style="background-image: url(assets/images/province/waterfront.png);">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mt-auto" style="margin: auto;">
        <h1 class="text-white">Build your itinerary</h1>
        <p class="text-white">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.</p>
        <a class="btn btn-primary mt-2 mt-sm-0 mr-sm-2 mx-1" href="/itinerary"> Explore more</a>
      </div>
      <div class="col-lg-5 mt-0 offset-lg-1">
        <div class="position-relative">
          <img class="img-fluid border-radius w-100" src="assets/images/province/Map_plan_your_trip.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</section>