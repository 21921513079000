
export class InfomapModel {
    constructor(
        public ProvinceText: string = "",
        public ProvinceDescription: string = "",
        public ProvinceUrlLink: string = "", //wildcard search from title
        public imgName: string = ""
    )   
    {
    }
}

