import { Deserializable } from "./deserializable";

export class DealsdashboardModel  implements Deserializable{
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public message: string;
    public country: string;
    public language: string;
    public section: string;
    public portal: string;
    public id: string;

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
  }