// Angular imports
import { Component, OnInit } from '@angular/core';

// React Imports
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';


// Global site text import
//import { dealsText } from '../../../app.component';

@Component({
  selector: 'test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})

export class TestPage implements OnInit {
   // site text variables for localization
   public FilterData: InfomapModel = new InfomapModel();
   public filterData: InfomapModel = new InfomapModel();
  constructor(
    //private data: DealsService
    ) {
  }

  public ngOnInit() : void {
    //TODO something
    this.filterData.imgName = 'hut';
    this.filterData.ProvinceDescription = 'Western Cape Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod';
    this.filterData.ProvinceText = 'Western Cape';
    this.filterData.ProvinceUrlLink = "https://www.youtube.com/watch?v=LgvseYYhqU0";
    this.FilterData = this.filterData;
  }
}
