// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DealsService } from '../../../services/deals.service';
// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
//import MarkerClusterer from '@googlemaps/markerclustererplus'

@Component({
  selector: 'app-deals-map-campaign',
  templateUrl: './deals-map-campaign.component.html',
  styleUrls: ['./deals-map-campaign.component.scss']
})
export class DealsMapCampaignComponent implements OnInit, OnDestroy {
  public title: string;

  public provinceTo: string;
  public cityTo: string;
  public type: string;
  public theme: string;
  public price: string;
  public citiesTo: any[] = [];
  public isMap = true;

  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @Input() FilterData: BehaviorSubject<SearchModel | undefined> = new BehaviorSubject<SearchModel | undefined>(undefined);
  @Output() provinceChange: EventEmitter<string> = new EventEmitter<string>();

  map: google.maps.Map;
  currlatitude : number = 0;
  currlongitude : number = 0;
  center = new google.maps.LatLng(this.currlatitude, this.currlongitude);
  marker: google.maps.Marker;
  mapMarkers: any = [];
  //markerCluster: MarkerClusterer;
  ProvinceText : string;
  HistoryText : string;
  isTracking : boolean;
  locationsArray: DealsModel[] = [];
  btnclass : string;
  Search: SearchModel = new SearchModel("","","","","","","");
  Provinces = [{ Name: "All", Value : "" } , { Name: "Gauteng", Value : "gauteng" }, { Name: "Mpumalanga", Value : "mpumalanga" }, { Name: "KwaZulu Natal", Value : "kwazulu-natal" }, { Name: "North West", Value : "north-west" }, { Name: "Limpopo", Value : "limpopo" }, { Name: "Western Cape", Value : "western-cape" }, { Name: "Free State", Value : "free-state" }, { Name: "Eastern Cape", Value : "eastern-cape" }, { Name: "Northern Cape", Value : "northern-cape" }];
  Cities: any[] = [];
  CityArray: any[] = [];
  sideActivityItems: any[] = [];
  sideStayItems: any[] = [];
  priceItems: any[] = [];

  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: 12
  };
  infoWindow: google.maps.InfoWindow;
  locations : any = [];
  markerContent : any = [];

  ngAfterViewInit() {

  }
  mapInit() {
    this.infoWindow = new google.maps.InfoWindow();
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
    this.mapMarkers = [];
    this.markerContent = [];
    //Where you are
    //this.marker = new google.maps.Marker({position: this.center, map:this.map})
    //Where you are

    var svgMarker = {
      path:
        "M16.4999 26.1392C21.9839 26.1392 26.4295 21.7302 26.4295 16.2915C26.4295 10.8528 21.9839 6.44391 16.4999 6.44391C11.0159 6.44391 6.57031 10.8528 6.57031 16.2915C6.57031 21.7302 11.0159 26.1392 16.4999 26.1392Z" +
        "M28.1675 4.79266C25.0509 1.70186 20.9077 0 16.5 0C12.0923 0 7.94906 1.70186 4.83254 4.79266C1.71602 7.88346 0 11.9932 0 16.3638C0 24.0753 5.43588 30.7324 12.9878 32.3548L16.5 37L20.0129 32.3548C27.5641 30.7324 33 24.0753 33 16.3638C33 11.9932 31.284 7.88346 28.1675 4.79266ZM16.5 4.27234C23.1826 4.27234 28.6192 9.66408 28.6192 16.2916C28.6192 22.919 23.1826 28.3108 16.5 28.3108C9.81737 28.3108 4.38076 22.919 4.38076 16.2916C4.38076 9.66408 9.81737 4.27234 16.5 4.27234Z",
      fillColor: "#FFCC00",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 1,
      anchor: new google.maps.Point(15, 30),
      PointerEvent: new PointerEvent('mouseover', {})
    };

    var count = 0;
    //console.log(this.locationsArray);
    for (let i of this.locationsArray) {
      if(i.cf_images[0]?.mx_image.includes("(")){
        i.cf_images[0].mx_image = "assets/images/image.png";
      }
      this.locations = [];
      for (let index = 0; index < i.cf_destinations.length; index++) {
        this.locations.push({
          lat: Number(i.cf_destinations[index]?.mx_latitude),
          lng: Number(i.cf_destinations[index]?.mx_longitude)
        });
        //this.codeLatLng(i.cf_destinations[index]?.mx_latitude, i.cf_destinations[index]?.mx_longitude);
      }

      var samePos1 = this.locationsArray.filter(result => (result.cf_destinations[0].mx_latitude === i.cf_destinations[0].mx_latitude && result.cf_destinations[0].mx_longitude === i.cf_destinations[0].mx_longitude && i.cf_address_line_1 === "") || (i.cf_address_line_1 !== "" && result.cf_address_line_1 === i.cf_address_line_1 && result.cf_address_line_2 === i.cf_address_line_2));
      var buttons = "";
      var buttons2 = "";
      var collg = "12";

      if(samePos1.length > 1){
        collg = "8";
        buttons = '<div class="col-lg-2 prev-next"><i class="fa fa-arrow-left" aria-hidden="true" id="arrows" style="color:grey;"></i></div>';
        buttons2 = '<div class="col-lg-2 prev-next"><a class="like" id="next-info-'+count+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top"                    title="Like" ><i class="fa fa-arrow-right" aria-hidden="true" id="arrow"></i></a></div>';
      }

      var content = '<div style="width:275px;padding:0;overflow:hidden;" class="item"> <div class="location-item-02 bg-holder text-center" style="background-image: url('+ i.cf_images[0]?.mx_image + ');height:150px;">              <div class="listing-image">                <div class="listing-quick-box-small">                            </div>                              </div>            </div>            <div class="listing-item">              <div class="listing-details">                <div class="listing-details-inner-small">        <div class="row">'+buttons+'<div class="col-lg-'+collg+' small-inner-marker"><div class="listing-title">                    <h6><a>' + i.cf_product.title.substring(0,20) + '</a></h6>                  </div>                  <div class="listing-info">                    <div class="mb-0">' + i.title.substring(0,25) + '</div>                  </div></div>'+buttons2+'</div>                          <br />                </div>                <div class="listing-bottom">                  <div class="col-6 text-left listing-shotleft">                    <a href="/" class="listing-price">ZAR ' + i.cf_price + '</a>                  </div>                  <div id="deals-but-'+count+'" class="col-6 text-right listing-shotright">                    <a class="listing-open" >Explore more ></a>                  </div>                </div>              </div>      </div>          </div>';
      //TODO: Do not delete
      //"<div class="listing-quick-box-custom-small">                  <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="food"> <i                      class="fas fa-utensils"></i> </a>                  <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="plane">                    <i class="fas fa-plane"></i> </a>                  <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="bus"> <i                      class="fas fa-bus"></i> </a>                  <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="hotel">                    <i class="fas fa-hotel"></i> </a>                </div>"
      //"<a class="like" id="close-info-'+count+'" style="margin-left:50px;cursor:pointer;" data-toggle="tooltip" data-placement="top"                    title="Like"> <i class="fa fa-plus-circle" style="margin-top:10px;"></i> </a>"
      var btn  = '<button class="_8s1fkb" tabindex="0" style="color: inherit; border: none; margin: 0px; padding: 0px; background: transparent; width: auto; font: inherit;"><div style="position: relative; cursor: pointer; transform-origin: 50% 50%; transition: transform 0.15s ease 0s; height: 28px; transform: scale(1.077);"><div style="align-items: center; background-color: rgb(34, 34, 34); border-radius: 28px; box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.18) 0px 1px 2px; color: rgb(255, 255, 255); display: flex; height: 28px; justify-content: center; padding: 0px 8px; position: relative; white-space: nowrap;"><a style="color: white;" target="_blank" href=' + i.sf_booking_link + ' class="_1rhps41">Book with Partner</a></div></div></button>';
      this.markerContent.push({
         content: content,
         count : count
      });

      var lbl =
      {
        text: 'btn' + count,
        color: 'white'
      }

      const tempMarker = new google.maps.Marker({ position: this.locations[0], map: this.map , icon : svgMarker , title: 'btn' + count});
      tempMarker.addListener('click', ((tempMarker, map, infoWindow) => {
        return () => {

          var counting = tempMarker.getTitle().split('n');
          var idnumber = Number(counting[1]);
          var samePos = this.locationsArray.filter(result => (result.cf_destinations[0].mx_latitude === i.cf_destinations[0].mx_latitude && result.cf_destinations[0].mx_longitude === i.cf_destinations[0].mx_longitude && i.cf_address_line_1 === "") || (i.cf_address_line_1 !== "" && result.cf_address_line_1 === i.cf_address_line_1 && result.cf_address_line_2 === i.cf_address_line_2));

          buttons = "";
          var button_prev = "";
          collg = "12";

          if(samePos.length > 1){
            collg = "8";
            button_prev = '<div class="col-lg-2 prev-next"><i class="fa fa-arrow-left" aria-hidden="true" id="arrows" style="color:grey;"></i></div>';
            buttons = '<div class="col-lg-2 prev-next"><a class="like" id="next-info-'+counting[1]+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top"                    title="Like" ><i class="fa fa-arrow-right" aria-hidden="true" id="arrow"></i></a></div>';
          }



          var content = '<div style="width:275px;padding:0;overflow:hidden;" class="item"> <div class="location-item-02 bg-holder text-center" style="background-image: url('+ i.cf_images[0]?.mx_image + ');height:150px;">              <div class="listing-image">                <div class="listing-quick-box-small">                            </div>                              </div>            </div>            <div class="listing-item">              <div class="listing-details">                <div class="listing-details-inner-small">        <div class="row">'+button_prev+'<div class="col-lg-'+collg+' small-inner-marker"><div class="listing-title">                    <h6><a>' + i.cf_product.title.substring(0,20) + '</a></h6>                  </div>                  <div class="listing-info">                    <div class="mb-0">' + i.title.substring(0,25) + '</div>                  </div></div>'+buttons+'</div>                            <br />                </div>                <div class="listing-bottom">                  <div class="col-6 text-left listing-shotleft">                    <a href="/" class="listing-price">ZAR ' + i.cf_price + '</a>                  </div>                  <div id="deals-but-'+idnumber+'" class="col-6 text-right listing-shotright">                    <a class="listing-open" >Explore more ></a>                  </div>                </div>              </div>      </div>          </div>';
          //infoWindow.setOptions({"maxWidth":275});
          //infoWindow.setContent(this.markerContent[Number(counting[1])].content);

          infoWindow.setContent(content);
          infoWindow.open(map, tempMarker);

          var activeButtons = document.getElementsByClassName('highlight');
          if(activeButtons.length > 0){
            this.renderer.removeClass(activeButtons[0],'highlight');
          }

          this.renderer.addClass(document.getElementById(tempMarker.getTitle()),'highlight');


          var container = document.getElementById('tabcontent');
          var scrollTo = document.getElementById('btn'+idnumber.toString());
          container.scrollTop = scrollTo.offsetTop;

            //Add onclick event to buttons
            setTimeout(() => {

              let dealsBtn = document.getElementById('deals-but-'+idnumber);
              dealsBtn.addEventListener('click' , () => this.goToDeals(i.entry_id));

              if(samePos.length > 1){


                //let closeBtn = document.getElementById('close-info-'+idnumber);
                //closeBtn.addEventListener('click' , () => infoWindow.close());
                var c = 1;

                var next = idnumber;
                var multi_content = '';

                var nnumber = (c === 1) ? idnumber : next;
                next = this.setNextNumber(i.entry_id,samePos);
                var cc = 1;
                for(let sp of samePos){
                  //Build multi content window

                  var display = (cc === 1) ? "block" : "none";
                  var multi_button_prev = '<a id="prev-info-inner-'+cc+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top" title="Like"><i class="fa fa-arrow-left" aria-hidden="true" id="arrows"></i></a>';
                  var multi_button_next = '<a class="like" id="next-info-inner-'+cc+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top"                    title="Like" ><i class="fa fa-arrow-right" aria-hidden="true" id="arrow"></i></a>';
                  multi_content += '<div id="inner-marker-'+cc+'" style="width:275px;padding:0;overflow:hidden;display:'+display+';" class="item inner-marker"> <div class="location-item-02 bg-holder text-center" style="background-image: url('+ sp.cf_images[0]?.mx_image + ');height:150px;">              <div class="listing-image">                <div class="listing-quick-box-small">                            </div>                              </div>            </div>            <div class="listing-item">              <div class="listing-details">                <div class="listing-details-inner-small">        <div class="row"><div class="col-lg-2 prev-next">'+multi_button_prev+'</div><div class="col-lg-8 small-inner-marker"><div class="listing-title">                    <h6><a>' + sp.cf_product.title.substring(0,20) + '</a></h6>                  </div>                  <div class="listing-info">                    <div class="mb-0">' + sp.title.substring(0,25) + '</div>                  </div></div><div class="col-lg-2 prev-next">'+multi_button_next+'</div></div>                            <br />                </div>                <div class="listing-bottom">                  <div class="col-6 text-left listing-shotleft">                    <a href="/" class="listing-price">ZAR ' + sp.cf_price + '</a>                  </div>                  <div id="deals-but-'+cc+'" class="col-6 text-right listing-shotright">                    <a class="listing-open" >Explore more ></a>                  </div>                </div>              </div>      </div>          </div>';
                  cc++;
                }


                let NextBtn = document.getElementById('next-info-'+nnumber);
                c++;

                NextBtn.addEventListener('click' , () => {

                        setTimeout(() => {

                          var ccc = 1;
                          for(let ideal of samePos){
                              if(ccc <= samePos.length){


                                let inner_next_button = document.getElementById('next-info-inner-'+ccc);
                                inner_next_button.addEventListener('click' , () => {

                                      var iii = inner_next_button.getAttribute('id');
                                      var iiii = iii.split('-');
                                      var nextbut = (Number(iiii[3]) === samePos.length) ?  1: Number(iiii[3])+1;
                                      var next2 = this.setNumber(samePos[nextbut-1].entry_id,samePos);
                                      let inners = document.getElementsByClassName('inner-marker');
                                      for (let index = 0; index < inners.length; index++) {
                                        inners[index].setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:none;');
                                      }
                                      let active_inner = document.getElementById('inner-marker-'+(nextbut));
                                      //alert(nextbut);
                                      active_inner.setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:block;');

                                      var activeButtons = document.getElementsByClassName('highlight');
                                      if(activeButtons.length > 0){
                                        this.renderer.removeClass(activeButtons[0],'highlight');
                                      }

                                      this.renderer.addClass(document.getElementById('btn'+next2.toString()),'highlight');
                                      var scrollTo = document.getElementById('btn'+next2.toString());
                                      container.scrollTop = scrollTo.offsetTop;
                                });

                                let inner_prev_button = document.getElementById('prev-info-inner-'+ccc);
                                inner_prev_button.addEventListener('click' , () => {

                                      var iii = inner_prev_button.getAttribute('id');
                                      var iiii = iii.split('-');

                                      var prevbut = (Number(iiii[3]) === 1) ? samePos.length : Number(iiii[3])-1;
                                      var next2 = this.setNumber(samePos[prevbut-1].entry_id,samePos);
                                      let inners = document.getElementsByClassName('inner-marker');
                                      for (let index = 0; index < inners.length; index++) {
                                        inners[index].setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:none;');
                                      }
                                      let active_inner2 = document.getElementById('inner-marker-'+(prevbut));
                                      //alert(nextbut);
                                      active_inner2.setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:block;');

                                      var activeButtons = document.getElementsByClassName('highlight');
                                      if(activeButtons.length > 0){
                                        this.renderer.removeClass(activeButtons[0],'highlight');
                                      }

                                      this.renderer.addClass(document.getElementById('btn'+next2.toString()),'highlight');
                                      var scrollTo1 = document.getElementById('btn'+next2.toString());
                                      container.scrollTop = scrollTo1.offsetTop;
                                });

                                let dealsBtn2 = document.getElementById('deals-but-'+ccc);
                                dealsBtn2.addEventListener('click' , () => this.goToDeals(ideal.entry_id));
                              }

                              ccc++;
                          }

                        }, 1000);

                        var listings = document.getElementsByClassName("mapListing");

                        for (let index = 0; index < listings.length; index++) {
                          this.renderer.removeClass(listings[index],'highlight');
                        }
                        this.renderer.addClass(document.getElementById('btn' + next), 'highlight');
                        this.infoWindow.setContent(multi_content);
                        this.mapOptions = {
                          center: this.center = new google.maps.LatLng(this.locations[0].lat, this.locations[0].long),
                          zoom: 12
                        };
                        var scrollTo2 = document.getElementById('btn'+next.toString());
                        container.scrollTop = scrollTo2.offsetTop;

                        this.infoWindow.open(this.map, this.mapMarkers[nnumber]);

                });


              }

              //let FwdBtn = document.getElementById('deals-but-'+idnumber);
              //FwdBtn.addEventListener('click' , () => this.goToDeals(i.entry_id));
            }, 1000);


        }
      })(tempMarker, this.map, this.infoWindow));
      this.mapMarkers.push(tempMarker);



      count ++;
    }
    this.map.setCenter(new google.maps.LatLng(Number(this.locationsArray[0]?.cf_destinations[0]?.mx_latitude), Number(this.locationsArray[0]?.cf_destinations[0]?.mx_longitude)));
    this.map.setZoom(7);

    //this.markerCluster = new MarkerClusterer(this.map, this.mapMarkers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' })

  }


  constructor(
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer:Renderer2
  ) {}

  public ngOnInit() : void {
    //Get all cities

    this.buildFilterMenu();

    this.FilterData.subscribe(result => {
      this.Search = result;
      this.Search.province = (this.Search.province === "Select province" || this.Search.province === null) ? '' : result.province.toLowerCase();
      this.Search.packageType = (this.Search.packageType === '0' || this.Search.packageType === null) ? '' : this.Search.packageType;
      this.Search.experienceType = (this.Search.experienceType === '0' || this.Search.experienceType === null) ? '' : this.Search.experienceType;
      this.Search.city = (this.Search.city === "City(optional)" || this.Search.city === null) ? '' : this.Search.city;
      this.getProvinceTo(this.Search.province);
    });

    this.cityTo = (this.Search.city === "") ? "Select city" : this.Search.city;

    const experiences = this.data.getAllExperiences();
    const themes = this.data.getAllPackageTypes();
    const prices = this.data.getAllPriceRanges();

    let e = experiences.filter(x => x.id === this.Search.experienceType);
    let t = themes.filter(x => x.id === this.Search.packageType);
    let p = prices.filter(x => x.name === this.Search.price);

    this.type = (this.Search.experienceType === "") ? "Select experience" : e[0].name;
    this.theme = (this.Search.packageType === "") ? "Select type" : t[0].name;
    this.price = (this.Search.price === "") ? "Price limit" : p[0].name;

    for (let i = 0; i < this.Cities.length; i++) {
      const data = this.Cities[i]?.[0]?.mx_city;
      if(data != null && data != "")
      {
        this.CityArray.push({
          City: data,
          Value: data
        });
      }
    }
    //Ignore city duplicates
    this.CityArray = this.CityArray.filter((v,i) => this.CityArray.findIndex(item => item.City == v.City) === i);

    //this.CityArray.push({
      //City: "All",
      //Value: ""
    //});

    this.CityArray.sort(this.compare);
    this.Provinces.sort(this.compareProvinces);

    setTimeout(() => {
      this.trackMe();
      this.search();
      }, 1000);

    this.buildFilterMenu();

    this.sideStayItems.push({
      name: "All",
      id: ""
    });

    this.sideActivityItems.push({
      name: "All",
      id: ""
    });

    this.sideStayItems.sort(this.compareTypes);
    this.sideActivityItems.sort(this.compareTypes);
   }

  public ngOnDestroy() : void {

  }
  compare(a, b) {
    const cityA = a.City.toUpperCase();
    const cityB = b.City.toUpperCase();
    let comparison = 0;
    if (cityA > cityB) {
      comparison = 1;
    } else if (cityA < cityB) {
      comparison = -1;
    }
    return comparison;
  }
  compareTypes(a, b) {
    const cityA = a.name.toUpperCase();
    const cityB = b.name.toUpperCase();
    let comparison = 0;
    if (cityA > cityB) {
      comparison = 1;
    } else if (cityA < cityB) {
      comparison = -1;
    }
    return comparison;
  }
  //Order by Province Name
  compareProvinces(a, b) {
    const proA = a.Name.toUpperCase();
    const proB = b.Name.toUpperCase();
    let comparison = 0;
    if (proA > proB) {
      comparison = 1;
    } else if (proA < proB) {
      comparison = -1;
    }
    return comparison;
  }
  getProvinceTo(region:string){
    //region = region.toLowerCase();

    if(region === 'All' || region === '' || region === null){

      this.Cities = this.data.getAllCitiesSingle();
    }
    else{
      this.Cities = this.data.getAllCitiesByRegion(region.toLowerCase().replace(' ','-'));
      let regions = region.split('-');
      let new_region = region.charAt(0).toUpperCase()+region.substr(1)

      if(regions.length > 1)
      new_region = regions[0].charAt(0).toUpperCase()+regions[0].substr(1)+' '+regions[1].charAt(0).toUpperCase()+regions[1].substr(1);

          document.getElementById("ddlProvince").innerHTML = new_region+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';
    }
    this.CityArray = [];
    this.cityTo = "Select city";
    for (let i = 0; i < this.Cities.length; i++) {
      const data = this.Cities[i]?.[0]?.mx_city;
      if(data != null && data != "")
      {
        this.CityArray.push({
          City: data,
          Value: data
        });
      }
    }

    this.CityArray.push({
      City: "All",
      Value: ""
    });

    this.CityArray.sort(this.compare);
  }
  //Find Current Location using browser
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  trackMe() {
    if (navigator.geolocation) {
      this.isTracking = true;
      navigator.geolocation.watchPosition((position) => {
        this.showTrackingPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  showPosition(position) {
    this.currlatitude = position.coords.latitude;
    this.currlongitude = position.coords.longitude;

    let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
    }
    else {
      this.marker.setPosition(location);
    }
  }

  showTrackingPosition(position) {
    this.currlatitude = position.coords.latitude;
    this.currlongitude = position.coords.longitude;

    localStorage.setItem('currlatitude', this.currlatitude.toString());
    localStorage.setItem('currlongitude', this.currlongitude.toString());

    let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    //this.map.panTo(location);
    //this.map.setZoom(11);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
    }
    else {
      this.marker.setPosition(location);
    }
  }
  //Find Current Location using browser

  clickbtn(event: any, btn)
  {

    var listings = document.getElementsByClassName("mapListing");

    for (let index = 0; index < listings.length; index++) {
      this.renderer.removeClass(listings[index],'highlight');
    }

    this.renderer.addClass(document.getElementById('btn' + btn.count), 'highlight');

    this.infoWindow.setContent(this.markerContent[btn.count].content);
    this.mapOptions = {
      center: this.center = new google.maps.LatLng(btn.lat, btn.long),
      zoom: 12
    };

    var container = document.getElementById('tabcontent');

    this.infoWindow.open(this.map, this.mapMarkers[btn.count]);
    var samePos = this.locationsArray.filter(result => (result.cf_destinations[0].mx_latitude === this.locationsArray[btn.count].cf_destinations[0].mx_latitude && result.cf_destinations[0].mx_longitude === this.locationsArray[btn.count].cf_destinations[0].mx_longitude && this.locationsArray[btn.count].cf_address_line_1 === "") || ((this.locationsArray[btn.count].cf_address_line_1 != "") && (result.cf_address_line_1 === this.locationsArray[btn.count].cf_address_line_1 && result.cf_address_line_2 === this.locationsArray[btn.count].cf_address_line_2)));
    setTimeout(() => {

      let dealsBtn = document.getElementById('deals-but-'+btn.count);
      dealsBtn.addEventListener('click' , () => this.goToDeals(this.locationsArray[btn.count].entry_id));

      if(samePos.length > 1){


        //let closeBtn = document.getElementById('close-info-'+idnumber);
        //closeBtn.addEventListener('click' , () => infoWindow.close());
        var c = 1;

        var next = btn.count;
        var multi_content = '';

        next = this.setNextNumber(samePos[c-1].entry_id,samePos);
        //var next2 = this.setNextNumber(samePos[c-1].entry_id,samePos);
        var nnumber = (c === 1) ? btn.count : next;

        var cc = 1;
        for(let sp of samePos){
          //Build multi content window

          var display = (cc === 1) ? "block" : "none";
          var multi_button_prev = '<a id="prev-info-inner-'+cc+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top" title="Like"><i class="fa fa-arrow-left" aria-hidden="true" id="arrows"></i></a>';
          var multi_button_next = '<a class="like" id="next-info-inner-'+cc+'" style="cursor:pointer;" data-toggle="tooltip" data-placement="top"                    title="Like" ><i class="fa fa-arrow-right" aria-hidden="true" id="arrow"></i></a>';
          multi_content += '<div id="inner-marker-'+cc+'" style="width:275px;padding:0;overflow:hidden;display:'+display+';" class="item inner-marker"> <div class="location-item-02 bg-holder text-center" style="background-image: url('+ sp.cf_images[0]?.mx_image + ');height:150px;">              <div class="listing-image">                <div class="listing-quick-box-small">                            </div>                              </div>            </div>            <div class="listing-item">              <div class="listing-details">                <div class="listing-details-inner-small">        <div class="row"><div class="col-lg-2 prev-next">'+multi_button_prev+'</div><div class="col-lg-8 small-inner-marker"><div class="listing-title">                    <h6><a>' + sp.cf_product.title.substring(0,20) + '</a></h6>                  </div>                  <div class="listing-info">                    <div class="mb-0">' + sp.title.substring(0,25) + '</div>                  </div></div><div class="col-lg-2 prev-next">'+multi_button_next+'</div></div>                            <br />                </div>                <div class="listing-bottom">                  <div class="col-6 text-left listing-shotleft">                    <a href="/" class="listing-price">ZAR ' + sp.cf_price + '</a>                  </div>                  <div id="deals-but-'+cc+'" class="col-6 text-right listing-shotright">                    <a class="listing-open" >Explore more ></a>                  </div>                </div>              </div>      </div>          </div>';
          cc++;
        }


        let NextBtn = document.getElementById('next-info-'+nnumber);


        NextBtn.addEventListener('click' , () => {

                setTimeout(() => {

                  var ccc = 1;
                  for(let ideal of samePos){

                      if(ccc <= samePos.length){

                        let inner_next_button = document.getElementById('next-info-inner-'+ccc);
                        inner_next_button.addEventListener('click' , () => {

                              var iii = inner_next_button.getAttribute('id');
                              var iiii = iii.split('-');
                              var nextbut = (Number(iiii[3]) === samePos.length) ?  1: Number(iiii[3])+1;
                              var next2 = this.setNumber(samePos[nextbut-1].entry_id,samePos);
                              let inners = document.getElementsByClassName('inner-marker');
                              for (let index = 0; index < inners.length; index++) {
                                inners[index].setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:none;');
                              }
                              let active_inner = document.getElementById('inner-marker-'+(nextbut));
                              //alert(nextbut);
                              active_inner.setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:block;');

                              var activeButtons = document.getElementsByClassName('highlight');
                              if(activeButtons.length > 0){
                                this.renderer.removeClass(activeButtons[0],'highlight');
                              }

                              this.renderer.addClass(document.getElementById('btn'+next2.toString()),'highlight');

                              var scrollTo1 = document.getElementById('btn'+next2.toString());
                              container.scrollTop = scrollTo1.offsetTop;


                        });

                        let inner_prev_button = document.getElementById('prev-info-inner-'+ccc);
                        inner_prev_button.addEventListener('click' , () => {

                              var iii = inner_prev_button.getAttribute('id');
                              var iiii = iii.split('-');

                              var prevbut = (Number(iiii[3]) === 1) ? samePos.length : Number(iiii[3])-1;
                              var next2 = this.setNumber(samePos[prevbut-1].entry_id,samePos);
                              let inners = document.getElementsByClassName('inner-marker');
                              for (let index = 0; index < inners.length; index++) {
                                inners[index].setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:none;');
                              }
                              let active_inner2 = document.getElementById('inner-marker-'+(prevbut));
                              //alert(nextbut);
                              active_inner2.setAttribute('style', 'width:275px;padding:0;overflow:hidden;display:block;');

                              var activeButtons = document.getElementsByClassName('highlight');
                              if(activeButtons.length > 0){
                                this.renderer.removeClass(activeButtons[0],'highlight');
                              }
                              this.renderer.addClass(document.getElementById('btn'+next2.toString()),'highlight');

                              var scrollTo1 = document.getElementById('btn'+next2.toString());
                              container.scrollTop = scrollTo1.offsetTop;

                        });


                        let dealsBtn2 = document.getElementById('deals-but-'+ccc);
                        dealsBtn2.addEventListener('click' , () => this.goToDeals(ideal.entry_id));
                      }

                      ccc++;
                  }

                }, 1000);

                var listings = document.getElementsByClassName("mapListing");

                for (let index = 0; index < listings.length; index++) {
                  this.renderer.removeClass(listings[index],'highlight');
                }

                this.infoWindow.setContent(multi_content);
                this.mapOptions = {
                  center: this.center = new google.maps.LatLng(this.locations[0].lat, this.locations[0].long),
                  zoom: 12
                };


                var nextN = this.setNumber(samePos[0].entry_id,samePos);
                this.renderer.addClass(document.getElementById('btn'+nextN), 'highlight');

                var scrollTo2 = document.getElementById('btn'+nextN);
                container.scrollTop = scrollTo2.offsetTop;

                this.infoWindow.open(this.map, this.mapMarkers[next]);

        });


      }
      c++;
    }, 1000);

  }


  //When clicking a button it matches it to a deal

  //Search via paramaters

  search()
  {
    this.locationsArray = [];
    this.isMap = true;
    //alert(this.Search.deal_ids);
    this.locationsArray = this.data.getAllByDealsByIds(this.Search);
    let mapBox = document.getElementById("map-box");
    let noResult = document.getElementById("no-results");

    mapBox.style.display = (this.locationsArray.length > 0) ? "block" : "none";
    noResult.style.display = (this.locationsArray.length > 0) ? "none" : "block";

    this.trackMe();
    this.mapInit();

    //console.log(this.Search);
  }
  OnsearchCity(event: any,btn)
  {
    if(btn == "province")
    {
      this.Search.city = "";
      document.getElementById("ddlCity").innerHTML = "Select city"+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';
      document.getElementById("ddlProvince").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';
      if(document.getElementById("ddlProvince").innerText == "All")
      {
        this.Search.province = "";
      }
      else
      {
        this.Search.province = event.currentTarget.innerText;
      }
    }



    this.search();
  }
  Onsearch(event: any,btn)
  {
    if(btn == "province")
    {
      this.Search.city = "";
      document.getElementById("ddlCity").innerHTML = "Select city"+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';
      document.getElementById("ddlProvince").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';

      if(document.getElementById("ddlProvince").innerText == "All")
      {
        this.Search.province = "";
      }
      else
      {
        this.Search.province = event.currentTarget.innerText.toLowerCase().replace(' ', '-');
      }
    }

    if(btn == "city")
    {
      document.getElementById("ddlCity").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';

      if(document.getElementById("ddlCity").innerText == "All")
      {
        this.Search.city = "";
      }
      else
      {
        this.Search.city = event.currentTarget.innerText;
      }
    }

    if(btn == "experience"){
      document.getElementById("ddlExperience").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';

      if(document.getElementById("ddlExperience").innerText == "All")
      {
        this.Search.experienceType = "";
      }
      else
      {
        this.Search.experienceType = document.getElementById(event.currentTarget.innerText).innerText;
      }
    }

    if(btn == "type"){
      document.getElementById("ddlType").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';

      if(document.getElementById("ddlType").innerText == "All")
      {
        this.Search.packageType = "";
      }
      else
      {
        this.Search.packageType = document.getElementById(event.currentTarget.innerText).innerText;
      }
    }

    if(btn == "price"){
      document.getElementById("ddlPrice").innerHTML = event.currentTarget.innerText+'<img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">';

      if(document.getElementById("ddlPrice").innerText == "All")
      {
        this.Search.price = "";
      }
      else
      {
        this.Search.price = document.getElementById(event.currentTarget.innerText).innerText;
      }
    }

    this.search();
  }
  goToDeals(deal: string){

    sessionStorage.setItem('dealId', deal);
    window.location.href = "/deals/"+deal;

  }
  buildFilterMenu(){
    this.sideActivityItems = [];
    this.sideActivityItems = this.data.getAllExperiences();

    this.sideStayItems = [];
    this.sideStayItems = this.data.getAllPackageTypes();

    this.priceItems = [];
    this.priceItems = this.data.getAllPriceRanges();
  }

  codeLatLng(lat, lng) {

    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({location: latlng}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[1]) {
         //formatted address
         alert(results[0].formatted_address)
        //find country name
             for (var i=0; i<results[0].address_components.length; i++) {
            for (var b=0;b<results[0].address_components[i].types.length;b++) {

            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                    //this is the object you are looking for
                    var city= results[0].address_components[i];
                    break;
                }
            }
        }
        //city data
        alert(city.short_name + " " + city.long_name)


        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
  }
  getPlaceHolder(url: string):string{
      var img = "assets/images/image.png";
      if(!url.includes("(")){
        img = url;
      }

      return img;
  }
  setNextNumber(current_entry_id: string, all: DealsModel[]): number{

    var prevItem = 0;
    for (let i of all) {
      var count = 0;
      for (let index of this.locationsArray) {
        if(i.entry_id === index.entry_id && index.entry_id != current_entry_id){
          prevItem = count;
          return prevItem;
        }
        count++;
      }
    }

    return prevItem;
  }
  setNumber(current_entry_id: string, all: DealsModel[]): number{

    var prevItem = 0;
    for (let i of all) {
      var count = 0;
      for (let index of this.locationsArray) {
        if(i.entry_id === index.entry_id && index.entry_id === current_entry_id){
          prevItem = count;
          return prevItem;
        }
        count++;
      }
    }

    return prevItem;
  }
  setNextButton(id: number, prev: number, container: HTMLElement, all: DealsModel[]): HTMLElement{

    //console.log("multi markers");
    //console.log(prev);

    let BackBtn = document.getElementById('prev-info-'+id);
    BackBtn.addEventListener('click' , () => {

          setTimeout(() => {
            let dealsBtn = document.getElementById('deals-but-'+prev);
            dealsBtn.addEventListener('click' , () => this.goToDeals(this.locationsArray[prev].entry_id));
          }, 1000);

          var listings = document.getElementsByClassName("mapListing");

          for (let index = 0; index < listings.length; index++) {
            this.renderer.removeClass(listings[index],'highlight');
          }

          this.renderer.addClass(document.getElementById('btn' + prev), 'highlight');
          this.infoWindow.setContent(this.markerContent[prev].content);
          this.mapOptions = {
            center: this.center = new google.maps.LatLng(this.locations[0].lat, this.locations[0].long),
            zoom: 12
          };

          var scrollTo = document.getElementById('btn'+prev.toString());
          container.scrollTop = scrollTo.offsetTop;

          this.infoWindow.open(this.map, this.mapMarkers[id]);

    });

    return BackBtn;
  }
  setPrevButton(){

  }
  scroll(el: HTMLElement) {
    el.scrollIntoView(true);
  }
}
