
export class NewTravelWeekModel {

        public name: string;
        public surname: string;
        public role: string;
        public email: string;
        public company_name: string;
        public csd_number: string;
        public website: string;
        public province: string;
        public town: string;
        public city: string


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
      }
}
