<!--<travelweek-bannernew [FilterData]="filterData"></travelweek-bannernew>-->

<section class="bg-white center" style="background: none; background-color:white!important;">
  <div class="p-4">
    <div class="consumer">
        <div>
            <img class="img-responsive" src="assets/images/travelweek2022/Web-Banners-B2C.png" />
        </div>
        <div class="p-4">
            <h3>#SHOTLEFTTRAVELWEEK22 IS HERE!</h3>
            <h3>5-11 September 2022</h3>
            <p class="mt-4">
                Yaaaas, Mzansi, #ShotLeftTravelWeek22 is back and the country is ready for you to bag your Soft life
                deals from <b>5 – 11 September</b>. Experience vibes all over your country, try something new from a variety
                of experiences that Mzansi has to offer with discounts of up to 50%. There is a selection of
                destinations that are vast as our southern slice of the African continent.
            </p>
            <b><a href="mailto:shotleft@southafrica.net">Consumer Enquiries</a></b>
        </div>
    </div>
    <div class="business mt-4">
        <div>
            <img class="img-responsive" src="assets/images/travelweek2022/Web-Banners-B2B_new.png" />
        </div>
        <div class="p-4">
            <h3>#SHOTLEFTTRAVELWEEK22 IS HERE!</h3>
            <h3>5-11 September 2022</h3>
            <p class="mt-4">
                Have you remembered to register your business for #ShotLeftTravelWeek2022? If not, we’re only a few
                days away from the cut-off date, 28 August. Your business cannot afford to miss out on this
                marketing exposure opportunity. Don’t miss out! Winter’s over, and our beautiful Mzansi is calling
                and, South Africans are exchanging the comforts of their comforters for something that will put a
                #Spring in their step this September. Walala wasala!
            </p>
            <b><a href="https://deals.southafrica.net/za/en/account/login">Register Now</a></b>
        </div>
    </div>
</div>
</section>

<app-deals-map [FilterData]="searchModel" [dealsData]="dealsDataFull" (provinceChange)="getNewProvince($event)"></app-deals-map>
<div id="mapscroll" style="margin-top:110px;"></div>
<app-featured-deals-small [FilterData]="searchModel" [dealsData]="dealsDataSingle"></app-featured-deals-small>
