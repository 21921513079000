<section class="   half-map" style="position:relative;padding-top:20px;max-height:650px;">
 <div class="container-fluid">
  <div class="row" style="margin-left:auto;margin-right:auto;width:80%;background: #FFCC00;border-radius: 30px;padding-top: 15px;padding-bottom: 15px;">
    <div class="col-sm text-center">
      
        <button class="btn btn-secondary-shot dropdown" type="button" id="ddlProvince" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Select province <img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">
          </button>
          <div class="dropdown-menu">
            <a *ngFor="let pr of Provinces" class="dropdown-item" (click)="Onsearch($event,'province');getProvinceTo(pr.Name);">
                  {{pr.Name}}
            </a>
          </div>
      
    </div>
    
    <div class="col-sm text-center">
      
        <button class="btn btn-secondary-shot dropdown" type="button" id="ddlCity" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{cityTo}} <img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">
          </button>
          <div class="dropdown-menu">
            <a *ngFor="let c of CityArray" class="dropdown-item" (click)="Onsearch($event,'city');">
                  {{c.City}} 
            </a>
          </div>
      
    </div>
    
    <div class="col-sm text-center">
      
        <button class="btn btn-secondary-shot dropdown" type="button" id="ddlExperience" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{type}} <img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">
          </button>
          <div class="dropdown-menu">
            <a *ngFor="let pr of sideActivityItems" (click)="Onsearch($event,'experience');" class="dropdown-item">
                  {{pr.name}}<div id="{{pr.name}}" style="display:none;">{{pr.id}}</div>
            </a>
          </div>
      
    </div>
    
    <div class="col-sm text-center">
      
        <button class="btn btn-secondary-shot dropdown" type="button" id="ddlType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{theme}} <img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">
          </button>
          <div class="dropdown-menu">
            <a *ngFor="let pr of sideStayItems" (click)="Onsearch($event,'type');" class="dropdown-item">
                  {{pr.name}}<div id="{{pr.name}}" style="display:none;">{{pr.id}}</div>
            </a>
            
          </div>
     
    </div>
    
    <div class="col-sm text-center">
      
      <button class="btn btn-secondary-shot dropdown" type="button" id="ddlPrice" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{price}} <img style="width:16px;float: right;margin-top:5px;" src="/assets/images/arrow-down.png">
        </button>
        <div class="dropdown-menu">
          <a *ngFor="let pr of priceItems" (click)="Onsearch($event,'price');" class="dropdown-item">
                {{pr.name}}<div id="{{pr.name}}" style="display:none;">{{pr.name}}</div>
          </a>
          
        </div>
   
  </div>
    
    
    <!--<div style="margin-left: 20px;">
      <div class="form-group mb-0 form-location">
        <input type="text" class="provinceSearch" title="Search Deals" placeholder="Search ..."
          name="Search.searchTerm" [(ngModel)]="Search.searchTerm" (input)="search()">
      </div>
    </div>-->

  </div>
 </div>
  
  
  <div id="map-box" class="container-fluid align-items-center" style="margin-top: 5%;">
    <div class="row ">

      <div class="col-xl-4 col-lg-7" id="divMapCards" style="padding-right:0;display:block;height: 650px;">
        <ul class="nav nav-tabs nav-tabs-02" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="tab-01-tab" data-toggle="tab" href="#tab-01" role="tab"
              aria-controls="tab-01" aria-selected="true">Popular items</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" id="tab-02-tab" data-toggle="tab" href="#tab-02" role="tab" aria-controls="tab-02"
              aria-selected="false">Saved items</a>
          </li> -->
          
        </ul>
        <div class="tab-content" id="myTabContent" style="height:650px;max-height:650px;min-height:650px;margin-bottom: 650px;display:block;">
          <div class="tab-pane fade active show" id="tab-01" role="tabpanel" aria-labelledby="tab-01-tab" >
            <ng-scrollbar class="virtual-scroll" thumbClass="white-scrollbars" [disabled]="true" style="height:550px;max-height:550px;min-height:550px;margin-bottom: 650px;">
              
                <div *ngFor="let loc of locationsArray;let i = index" [attr.data-index]="i" class="example-item" style="min-width:100%;">
                  <div style="cursor:pointer;" (click)="clickbtn($event, this.markerContent[i])" style="min-width:100%;" class="listing-item listing-list mt-4 mapListing"
                id="btn{{this.markerContent[i].count}}" #btn{{this.markerContent[i].count}}>
                <div class="row no-gutters">
                  <div class="col-xl-4 col-lg-5 col-md-5">
                    <div class="listing-image bg-holder" style="display:block;width:100%;height:100%;background-image: url({{loc.cf_images[0]?.mx_image}});">
                      <div class="listing-quick-box">
                        <!--<a class="category"> <i class="flaticon-coffee-cup"></i> {{loc.cf_travel_mode}}</a>-->
                        <a class="popup popup-single" href="{{loc.cf_images[0]?.mx_image}}" data-toggle="tooltip"
                          data-placement="top" target="_blank" title="Zoom"> 
                          <div *ngIf="loc.cf_discount != '0'" class="SpecialdiscountSmall">&nbsp;&nbsp;&nbsp;&nbsp;{{loc.cf_discount}}% Off&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <i class="fas fa-search-plus"></i>  </a>
                        <!-- <a class="like" data-toggle="tooltip" data-placement="top" title="Like"> <i
                            class="far fa-heart"></i>
                        </a> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-7">
                    <div class="listing-details h-100">
                      <div class="listing-details-inner">
                        <div class="listing-title">
                          <h6><a>{{loc.cf_product.title.substring(0,60)}}</a></h6>
                        </div>
                        <div class="listing-rating-call">
                          <!--<a class="listing-rating"><img class="img-fluid"
                              src="assets/images/province/provincepg/stars.png" alt=""></a>-->
                        </div>
                        <div class="listing-info">
                          <p class="mb-0">{{loc.cf_deal_summary}}</p>
                        </div>
                      </div>
                      <div class="listing-bottom">
                        <div class="col-7 text-left listing-shotleft">
                          <a><i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;{{loc.cf_destinations[0]?.mx_city}}</a>
                        </div>
                        <div class="col-5 text-right listing-shotright">
                          <a (click)="goToDeals(loc.url_title)">Explore more ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </div>
              
            </ng-scrollbar>

            
          </div>
          <!--<div class="tab-pane fade" id="tab-02" role="tabpanel" aria-labelledby="tab-02-tab">
            <div class="scrollbar scroll_dark h-60vh" id="tabcontent" style="transition: 00.3s;">
              <div style="cursor:pointer;" (click)="clickbtn($event, this.markerContent[i])" class="listing-item listing-list mt-4 mapListing"
                *ngFor="let loc of locationsArray ; let i = index" [attr.data-index]="i"
                id="btn{{this.markerContent[i].count}}" #btn{{this.markerContent[i].count}}>
                <div class="row no-gutters">
                  <div class="col-xl-4 col-lg-5 col-md-5">
                    
                    
                    <div class="listing-image bg-holder" style="background-image: url({{loc.cf_images[0]?.mx_image}});display:block;width:100%;height:100%;">
                      
                      <div class="listing-quick-box">
                        <a class="category"> <i class="flaticon-coffee-cup"></i> {{loc.cf_travel_mode}}</a>
                        <a class="popup popup-single" href="{{loc.cf_images[0]?.mx_image}}" data-toggle="tooltip"
                          data-placement="top" title="Zoom"> <i class="fas fa-search-plus"></i> </a>
                        <a class="like" data-toggle="tooltip" data-placement="top" title="Like"> <i
                            class="far fa-heart"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-7">
                    <div class="listing-details h-100">
                      <div class="listing-details-inner">
                        <div class="listing-title">
                          <h6><a>{{loc.cf_product.title.substring(0,20)}}</a></h6>
                        </div>
                        <div class="listing-rating-call">
                          <a class="listing-rating"><img class="img-fluid"
                              src="assets/images/province/provincepg/stars.png" alt=""></a>
                        </div>
                        <div class="listing-info">
                          <p class="mb-0">{{loc.cf_deal_summary}}</p>
                        </div>
                      </div>
                      <div class="listing-bottom">
                        <div class="col-6 text-left listing-shotleft">
                          <a><i class="fas fa-map-marker-alt"></i>{{loc.cf_destinations[0]?.mx_city}}</a>
                        </div>
                        <div class="col-6 text-right listing-shotright">
                          <a (click)="goToDeals(loc.entry_id)">Explore more ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>-->
        
        </div>
      </div>

      <div class="col-xl-8 col-lg-5" id="divmap" style="padding-left:0px;">
        <div class="half-map-full">
          <div #map class="map-canvas" style="height:600px;"></div>
        </div>
      </div>
    </div>
  </div>

  <div id="no-results" class="container-fluid align-items-center" style="display: none;">
    <div class="row">
      <div class="col-lg-12" style="margin-left: 20%;margin-right: auto;margin-top: 30px;padding-left:20px;">
        <h4>No Results</h4>
        <p>
           Try adjusting your search by removing filters.
        </p>
      </div>
      
    </div>
       
  
  </div>
</section>
