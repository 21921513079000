// angular imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Model Imports
import { Deals, DealsModel } from '../models/main/deals/deals.model';
import { SearchModel } from '../models/main/deals/search.model';


// Dummy data import
import tenreasonsData from '../../assets/dummy-data/tenreasons.json';

import { ExperiencesModel } from '../models/main/deals/experiences.model';
import { PackagetypesModel } from '../models/main/deals/packagetypes.model';
import { BannersModel } from '../models/main/deals/banners.model';
import { DealsdashboardModel } from '../models/main/dealsdashboard/dealsdashboard.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TenReasonsModel } from '../models/main/tenreasons/tenreasons.model';

@Injectable({ providedIn: 'root' })


export class TenReasonsService {
    public tenreasons: TenReasonsModel[];
    public data = tenreasonsData;
        
    constructor(
        private http: HttpClient
        ) { }
    public ngOnInit() : void {
        
    }
    
    //TODO add service calls here
    getTenReasons(): TenReasonsModel[] {
        const filteredData = this.data;
        return filteredData;
    }
}


