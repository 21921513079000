<section id="homebanner" class="page-title bg-holder easterBanner">
  <div class="easter_video" (click)="scrollToTableVidFun()">
    <img style="margin-top:0px;margin-right:20px;" src="/assets/images/easter/play-icon.png">
  </div>
</section>
<section class="space-ptb" style="padding-bottom:20px;background-color: #f4f4f4;">
  <div class="container" style="margin-left:0px;max-width:100%;padding-left:0px;">
      <section>
          <div class="container" style="max-width:90%;">
            <div class="row">
              <div class="col-12 text-center">
                <p style="font-size: 25px;" class="text-black mb-4 firstBlock"> 
                  {{sideCampaignItems[0].short_description}}
                  </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <p style="font-size: 25px;" class="text-black mb-4 mb-md-5"> 
                  {{sideCampaignItems[0].long_description}}
                  </p>
              </div>
            </div>
          </div>
      </section>
  </div>    
</section>

<section class="space-ptb videoContainer" id="easterVideo">
  <div class="container" style="margin-left:0px;max-width:100%;">
      <section>
          <div class="container" style="max-width:90%;">
            <div class="row">
              <div class="col-12 text-center">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/3oLA0hJAOQ0?start=500" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
      </section>
  </div>    
</section>
<app-easter-deals [FilterData]="dealsData"></app-easter-deals>



