import { Renderer2 } from '@angular/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

// React Imports
import { BehaviorSubject, Subscription } from 'rxjs';
// React Imports
import { InfomapModel } from 'src/app/models/main/deals/infomap.model';
import { TenReasonsModel } from 'src/app/models/main/tenreasons/tenreasons.model';
import { TenReasonsService } from 'src/app/services/tenreasons.service';


// Global site text import
//import { dealsText } from '../../../app.component';

@Component({
  selector: 'ten-reasons-why',
  templateUrl: './ten-reasons.component.html',
  styleUrls: ['./ten-reasons.component.scss']
})

export class TenReasonsPage implements OnInit {
   // site text variables for localization
   TenReasons: TenReasonsModel[] = [];
   constructor(
    private tenReasonsData: TenReasonsService,
    ) {
  }

  public ngOnInit() : void {
    //TODO something
    this.TenReasons = this.tenReasonsData.getTenReasons();
  }
}
