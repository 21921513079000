<footer
  class="footer space-ptb bg-holder bg-overlay-black-50 bg-image-shotleft"
  style="background-color: black"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-6 mb-4 mb-lg-0">
            <h5 class="text-white mb-2 mb-sm-4">Activities</h5>
            <div class="footer-link">
              <ul class="list-unstyled mb-0">
                <li
                  *ngFor="let loca of activities; let i = index"
                  [attr.data-index]="i"
                >
                  <a (click)="goToActivity(loca.id)">{{ loca.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 mb-4 mb-lg-0">
            <h5 class="text-white mb-2 mb-sm-4">Stay</h5>
            <div class="footer-link">
              <ul class="list-unstyled mb-0">
                <li
                  *ngFor="let locb of stays; let i = index"
                  [attr.data-index]="i"
                >
                  <a (click)="goToStay(locb.id)">{{ locb.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row">
          <div class="col-6 mb-4 mb-lg-0">
            <h5 class="text-white mb-2 mb-sm-4">Mzansi</h5>
            <div class="footer-link">
              <ul class="list-unstyled mb-0">
                <li><a (click)="goToProvince('Gauteng')">Gauteng</a></li>
                <li><a (click)="goToProvince('Limpopo')">Limpopo</a></li>
                <li><a (click)="goToProvince('Mpumalanga')">Mpumalanga</a></li>
                <li>
                  <a (click)="goToProvince('Kwazulu Natal')">KwaZulu Natal</a>
                </li>
                <li>
                  <a (click)="goToProvince('Western Cape')">Western Cape</a>
                </li>
                <li>
                  <a (click)="goToProvince('Eastern Cape')">Eastern Cape</a>
                </li>
                <li>
                  <a (click)="goToProvince('Northern Cape')">Northern Cape</a>
                </li>
                <li><a (click)="goToProvince('North West')">North West</a></li>
                <li><a (click)="goToProvince('Free state')">Free State</a></li>
              </ul>
            </div>
          </div>
          <div class="col-6 mb-4 mb-sm-0">
            <h5 class="text-white mb-2 mb-sm-4">Tools</h5>
            <div class="footer-link">
              <ul class="list-unstyled mb-0">
                <li><a href="/itinerary">Itinerary Builder</a></li>
                <!--<li>
                  <a href="https://deals.southafrica.net/za/en/deals" target="_blank">
                    Trade - load deals
                  </a>
                </li>-->
                <li><a href="https://profile.shotleft.co.za">Partner Platform</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-lg-5 mt-0 pt-lg-4 pt-0">
      <div class="col-md-12 text-md-left text-left mt-4 mt-md-0">
        <ul class="list-unstyled mb-0 social-icon text-center">
          <li>
            <a
              style="cursor: pointer"
              (click)="
                socialMedia('https://www.facebook.com/shotleft', 'Facebook')
              "
              ><i class="fab fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a
              style="cursor: pointer"
              (click)="
                socialMedia('https://www.instagram.com/shotleft', 'Instagram')
              "
              ><i class="fab fa-instagram"></i
            ></a>
          </li>
          <li>
            <a
              style="cursor: pointer"
              (click)="socialMedia('https://x.com/shotleft', 'X')"
              ><i class="fa-brands fa-x-twitter"></i
            ></a>
          </li>
          <li>
            <a
              style="cursor: pointer"
              (click)="
                socialMedia(
                  'https://www.youtube.com/user/shotleft',
                  'Youtube'
                )
              "
              ><i class="fab fa-youtube"></i
            ></a>
          </li>
        </ul>
        <ul class="list-unstyled mt-5 social-icon text-center">
          <li>
            <a href="/contact-us">Contact</a>
          </li>
          <li class="pipes">
            <a>|</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.southafrica.net/za/en/travel/page/terms-and-conditions"
              >Terms and Conditions</a
            >
          </li>
          <li class="pipes">
            <a>|</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.southafrica.net/za/en/travel/page/disclaimer"
              >Disclaimer</a
            >
          </li>
          <li class="pipes">
            <a>|</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.southafrica.net/za/en/travel/page/privacy-policy"
              >Privacy Policy</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div
      class="modal-body"
      style="background: rgb(235, 32, 39); border: none; color: white"
    >
      <div class="row" style="padding: 20px">
        <div class="col-md-2">
          <i class="fas fa-cookie-bite fa-4x"></i>
        </div>
        <div class="col-md-10">
          Cookies help us deliver content of interest and improve website
          functionality. By using our site you agree to our
          <a
            target="_blank"
            href="https://www.southafrica.net/za/en/travel/page/privacy-policy"
            >cookies policy</a
          >
        </div>
      </div>
      <button
        style="float: right; color: white"
        class="btn btn-lg btn-primary"
        (click)="acceptCookies()"
      >
        Accept cookies
      </button>
    </div>
  </ng-template>
</footer>
<button
  style="display: none"
  id="modal_button"
  class="btn btn-lg btn-outline-primary"
  (click)="show(content)"
>
  Launch demo modal
</button>
