
<!--=================================
banner -->
<app-provinces-banner></app-provinces-banner>

<!--=================================
Advertise -->
<!-- Hide travel campaign banner -->
  <app-banner-adds [bannersArray]="bannersArray"></app-banner-adds>
<!--=================================
Advertise -->
<!--=================================
About -->
<app-about></app-about>
<!--=================================
About -->
<app-featured-deals [FilterData]="dealsData"></app-featured-deals>
<div>
  <app-info-map></app-info-map>
</div>

<!--=================================
Advertise -->
<section class="bg-overlay-black-50 center" style="background-color: white;">
    <div class="row center" style="margin-left:0px;margin-right:0px;">
      <div class="col-lg-6" style="background-color: white;">
        <div class="form-group">
          <div class="col-md-12 ml-5">
            <h2 class="text-shotleft mt-4" >Top reasons</h2>
            <h3 class="text-black mb-4 mb-md-5">to travel in South Africa</h3>
            <p class="text-black mb-4 mb-md-5 mr-5" >
              Want to take a trip or go on a holiday in your beautiful country? No need to travel like a typical tourist, this is your home and your own backyard. There are so many options outside of just game-drives and beaches. Explore some options and get to know your country a bit more with this list of reasons to leave your couch right this moment.
            </p>
            <a class="btn btn-primary mt-2 mt-sm-0 mr-sm-2 mx-1" href="/ten-reasons"> Explore more</a>          
              </div>
        </div>

      </div>
      
      <div class="col-lg-6 text-left" style="background-image: url(assets/images/10reasons-bg.png);background-size: cover;background-position: center center;background-repeat: no-repeat;height:355.6px;">
        <div class="row">
        <div class="col-lg-7 text-left" style="padding-left:8%;">

        </div>
        <br/>
        </div>
      </div>
      
      <!--<div class="col-lg-6 text-left" style="background-image: url(assets/images/10reasons-bg.png);background-size: cover;background-position: center center;background-repeat: no-repeat;">
        <div class="row">
        <div class="col-lg-7 text-left" style="padding-left:8%;">

        </div>
        <br/>
        </div>
      </div>-->
    </div>
</section>
<!--=================================
Advertise -->

<!--=================================
Advertise -->
<section class="space-ptb bg-holder bg-image-shotleft" style="background-image: url(assets/images/hit-the-road.png);padding-top:170px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 text-left">

      </div>
      <div class="col-lg-6 text-left">
        <h2 class="text-white" >Hit the road</h2>
        <p class="text-white mb-4 mb-md-5">Mzansi awaits! It’s time to hit the road and get out there. What is it going to be this time magenge? A trip out of town to enjoy some breath-taking views? A holiday to the coast to soak in the South African sun on our pristine beaches? Or, just a much-needed getaway back to ekhaya? Whatever adventure you’re looking for, we can help get you there with our travel tools that make it easier for you to plan your journey.</p>
        <a class="btn btn-primary mt-2 mt-sm-0 mr-sm-2 mx-1" href="/itinerary"> Explore more</a>
      </div>
    </div>
  </div>
</section>
<!--=================================
Advertise -->
<!--<app-build-itinerary></app-build-itinerary>-->
