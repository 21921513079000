<div class="hideScroll">
<nav class="navbar navbar-static-top navbar-expand-lg header-sticky">
    <div class="container-fluid " >
      <a class="navbar-brand" href="/">
        <img class="img-fluid" src="assets/images/logo.svg" alt="logo">
      </a>
      <!--<div class="navbar-collapse collapse justify-content-start">
        <ul class="nav navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Experiences<i class="fas fa-xs"></i></a>

          </li>
          <li class="dropdown nav-item">
            <a href="/" class="nav-link" data-toggle="dropdown">Accomodation<i class="fas fa-xs"></i></a>

          </li>
      </ul>
      </div>-->
<div class="d-block d-md-flex" style="padding-top:20px;">

  <!-- <div class="d-inline-block mr-4">
    <a class="text-shotleft" data-toggle="modal" data-target="#loginModal" href="#"><i class="fa fa-user pl-2 text-primary"></i> Login</a>
  </div>
  </div> -->
  <div class="d-inline-block mr-4">
  <a (click)="openNav()"><img src="assets/images/Hamburger-menu.png" style="width:16px;cursor:pointer;" /></a>
  </div>

</div>
</div>
</nav>
<div id="mySidenav" class="sidenav">

  <div class="container">

    <div class="row">

        <div class="col-lg-4">

          <a style="margin-top:65px;" data-toggle="collapse" data-parent="#mySidenav" (click)="openCloseMenu($event)" href="#shotLeftSideOne" aria-expanded="true" aria-controls="shotLeftSideOne" class="dropdown-btn">Activities
            <i id="click1" class="fa fa-plus"></i>
          </a>
          <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideOne">
            <a *ngFor="let loc of sideActivityItems ; let i = index" [attr.data-index]="i" (click)="getActivities($event, loc.id)">{{loc.name}}</a>
          </div>
          <a data-toggle="collapse" data-parent="#mySidenav" (click)="openCloseMenu($event)" href="#shotLeftSideTwo" aria-expanded="true" aria-controls="shotLeftSideTwo" class="dropdown-btn">Stay
            <i id="click1" class="fa fa-plus"></i>
          </a>
          <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideTwo">
            <a *ngFor="let loc of sideStayItems ; let i = index" [attr.data-index]="i" (click)="getStays(loc.id)">{{loc.name}}</a>
          </div>
          <a data-toggle="collapse" data-parent="#mySidenav" (click)="openCloseMenu($event)" href="#shotLeftSideThree" aria-expanded="true" aria-controls="shotLeftSideThree" class="dropdown-btn">Mzansi
            <i id="click1" class="fa fa-plus"></i>
          </a>
          <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideThree">
            <a (click)="goToProvince('Gauteng')">Gauteng</a>
            <a (click)="goToProvince('Limpopo')">Limpopo</a>
            <a (click)="goToProvince('Mpumalanga')">Mpumalanga</a>
            <a (click)="goToProvince('Kwazulu Natal')">KwaZulu Natal</a>
            <a (click)="goToProvince('Western Cape')">Western Cape</a>
            <a (click)="goToProvince('Eastern Cape')">Eastern Cape</a>
            <a (click)="goToProvince('Nortern Cape')">Northern Cape</a>
            <a (click)="goToProvince('North West')">North West</a>
            <a (click)="goToProvince('Free state')">Free State</a>
        </div>
         <!--
         Hide travel week campaign  
        <a data-toggle="collapse" data-parent="#mySidenav" (click)="openCloseMenu($event)" href="#shotLeftSideFive" aria-expanded="true" aria-controls="shotLeftSideFive" class="dropdown-btn">Campaigns
            <i id="click1" class="fa fa-plus"></i>
          </a>
 End of travel week capmaing menu
        -->
          <!-- <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideFive">
            <a *ngFor="let camp of sideCampaignItems ; let i = index" [attr.data-index]="i" href="/{{camp.url}}">{{camp.title}}</a>
          </div> -->
         
          <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideFive"> -->
           <a *ngFor="let camp of sideCampaignItems ; let i = index" [attr.data-index]="i" href="/campaign/{{camp.id}}">{{camp.title}}</a> -->
            <a href="/travelweek">Travel Week 2022</a>
          </div>
       

          <a data-toggle="collapse" data-parent="#mySidenav" (click)="openCloseMenu($event)" href="#shotLeftSideFour" aria-expanded="true" aria-controls="shotLeftSideFour" class="dropdown-btn">Tools
            <i id="click1" class="fa fa-plus"></i>
          </a>
          <div class="dropdown-container panel-collapse collapse in" role="tabpanel" id="shotLeftSideFour">
            <a href="/itinerary">Itinerary builder</a>
          </div>
          <a href="/contact-us">Contact</a>
          <a target="_blank" href="https://www.southafrica.net/za/en/travel/page/terms-and-conditions">Terms and Conditions</a>
          <a target="_blank" href="https://www.southafrica.net/za/en/travel/page/disclaimer">Disclaimer</a>
          <a target="_blank" href="https://www.southafrica.net/za/en/travel/page/privacy-policy">Privacy Policy</a>
        </div>
        <div class="col-md-8" style="position:relative;margin-top:65px">
          <div class="row">
                <div *ngIf="loading" class="loader">

                </div>

                  <div *ngIf="!loading" class="col-sm-12" >

                    <app-sidemenu-carousel [locationsArray]="locationsArray"></app-sidemenu-carousel>

                  </div>


            </div>
            <div class="row">
              <div *ngIf="!loading">

                <div class="col-sm-12">
                  <p style="margin-top:15px;">
                    Heita! So, you want to get out there and you’re looking for something great within your budget. Here are some of the hottest deals for stays and activities to suit the kind of experience you’re looking for. It’s your country, Enjoy it!
                  </p>
                  </div>
              </div>

            </div>
        </div>
      </div>
   </div>
   <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
</div>
</div>
