import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-easter-season-page',
  templateUrl: './easter-season-page.component.html',
  styleUrls: ['./easter-season-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EasterSeasonPageComponent implements OnInit {

  dealsData: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  Search: SearchModel = new SearchModel();
  sideCampaignItems: any[] = [];

  constructor(private activatedRoute: ActivatedRoute,private campaigns: CampaignService,) { }

  ngOnInit(): void {
    this.getAllFilterLive(this.Search);
    this.sideCampaignItems = this.campaigns.getAllCampaign();
    // document.getElementById("seatAtTheTable").scrollIntoView({ behavior: "smooth" });
  }

  scrollToTableVidFun(){
    var divEle = document.getElementById("easterVideo");
    divEle.scrollIntoView({
      behavior: "smooth"
    });
  }

  getAllFilterLive(search : SearchModel): void{

    this.activatedRoute.data.subscribe(response => {
       var data = response.dealsSingle;
       if(data != undefined){
        this.dealsData.next(data.
          filter(result =>
          result.status == "open" &&
          (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
          new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
          (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
          (result.cf_package_type === search.packageType || search.packageType === "") &&
          (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
          (result.cf_campaign === search.campaign || search.campaign === "") &&
          (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
          ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
          //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
          (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
          ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
          ))
          //console.log(this.dealsData)
       }

    });

  }

}
