import { AnimationMetadataType, keyframes, trigger } from '@angular/animations';
import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { DealsService } from 'src/app/services/deals.service';
import { CampaignService } from 'src/app/services/campaign.service'
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  //@Input() public sideNavToggle = false;
  //@Output() public readonly clickHamburgerEvent: EventEmitter<any> = new EventEmitter<any>();
  dropdownimage = "plus";
  dropdown: HTMLCollection;
  locationsArray: BehaviorSubject<DealsModel[] | undefined> = new BehaviorSubject<DealsModel[] | undefined>(undefined);
  Search: SearchModel = new SearchModel();
  isOpen = false;
  sideActivityItems: any[] = [];
  sideStayItems: any[] = [];
  sideCampaignItems: any[] = [];
  customOptions: OwlOptions;
  public loading = false;
  slides: any[] = [];
  deals: any[] = [];
  dealsSingle: any[] = [];

  constructor(
    private data: DealsService,
    private campaigns: CampaignService,
    private renderer:Renderer2,
    private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
    ) {

     }

  public ngOnInit() : void {

     //console.log(this.locationsArray.value);
      var sidenav = document.getElementById("mySidenav");

      //if(this.locationsArray.value === undefined)
        //this.locationsArray.next(this.data.getAllFilter(this.Search).slice(0,20));

        setTimeout(function(){
                this.loading = false;
              }.bind(this),1000);
        this.getDealsSingle().subscribe(data => {
        this.dealsSingle = data;
    })
    this.buildSideMenu();

    //alert(this.locationsArray);
  }
  public ngAfterViewInit() : void{

    //document.getElementById("mySidenav").style.width = "100%";

    var sidenav = document.getElementById("mySidenav");
    //sidenav.style.width = "0";
    //setTimeout(function(){
      sidenav.style.width = "0";
    //},0.000000000001);
  }
  /* Set the width of the side navigation to 250px */
  openNav() {
    this.isOpen = true;
    document.getElementById("mySidenav").style.transition = "0.5s";
    document.getElementById("mySidenav").style.width = "100%";
    this.locationsArray.next(this.getAllFilter(this.Search).slice(0,20));

    //alert(this.isOpen);
  }

  /* Set the width of the side navigation to 0 */
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  openCloseMenu(event: Event){
    var item = event.target["children"];
    if(item[0].classList.contains("fa-plus")){
        this.renderer.removeClass(item[0], "fa-plus");
        this.renderer.addClass(item[0], "fa-minus");
        this.renderer.addClass(event.target, "drop-active");
    }
    else if(item[0].classList.contains("fa-minus")){
      this.renderer.removeClass(item[0], "fa-minus");
      this.renderer.addClass(item[0], "fa-plus");
      this.renderer.removeClass(event.target, "drop-active");
    }

  }
  goToProvince(province: string){

    sessionStorage.setItem('provinceTo', province);
    sessionStorage.setItem('provinceFrom', 'Select province');

    window.location.href = "/province/"+province.replace(' ', '-').toLowerCase();

  }
  buildSideMenu(){
       this.sideActivityItems = this.data.getAllExperiences();
       this.sideStayItems = this.data.getAllPackageTypes();
       this.sideCampaignItems = this.campaigns.getAllCampaign();
  }
  getActivities(event: any, id: string){
    var items = document.getElementsByClassName('select-active');
    for (let index = 0; index < items.length; index++) {
      this.renderer.removeClass(items[index],'select-active');
    }

    this.renderer.addClass(event.target, "select-active");

    this.loading = true;
    let d = this.getAllByExperiences(id).slice(0,20);
    this.locationsArray.next((d.length > 0) ? d : this.getAllFilter(this.Search).slice(0,20));

    this.ngOnInit();

  }
  getStays(id: string){
    var items = document.getElementsByClassName('select-active');
    for (let index = 0; index < items.length; index++) {
      this.renderer.removeClass(items[index],'select-active');
    }

    this.renderer.addClass(event.target, "select-active");

    this.loading = true;
    let d = this.getAllByType(id).slice(0,20);

    this.locationsArray.next(null);

    this.locationsArray.next((d.length > 0) ? d : this.getAllFilter(this.Search).slice(0,20));

    this.ngOnInit();

  }
  getDealsSingle(): Observable<DealsModel[]> {
    //return this.http.get<any[]>('https://api.shotleft.co.za/latestdeal');    //'https://api.shotleft.co.za/travelweeklatest');
    return this.http.get<any[]>('https://tradeplatform-liveapi.azurewebsites.net/api/v1/Products/GetOpenDeals');    //'https://api.shotleft.co.za/travelweeklatest');

  }
  getAllFilter(search : SearchModel): DealsModel[]{
       var data = this.dealsSingle;

       if(data != undefined){
        data = data.
          filter(result =>
          result.status == "open" &&
          (new Date(Number(result.cf_publish_from)*1000) <= new Date() && //Start date
          new Date(Number(result.expiration_date)*1000) >= new Date()) && //Expiry date
          (result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0) && //Has images and destinations
          (result.cf_package_type === search.packageType || search.packageType === "") &&
          (result.cf_experience_type === search.experienceType || search.experienceType === "") &&
          (result.cf_campaign === search.campaign || search.campaign === "") &&
          (Number(result.cf_discount) > Number(search.discount) || search.discount === "") &&
          ((Number(result.cf_price) <= Number(search.price)  && search.price !== "") || search.price === "") &&
          //(result.title.toLocaleLowerCase().includes(search.searchTerm.toLocaleLowerCase()) || search.searchTerm == "") &&
          (result.cf_destinations[0]?.mx_province === search.province.toLocaleLowerCase() || search.province === "" || search.province === "south-africa") &&
          ((result.cf_destinations[0]?.mx_city === search.city || search.city == "") && result.cf_destinations[0]?.mx_city != "")
          )

       }



    return data;
  }

  getAllByType(type: string): DealsModel[]{


    var filteredData = this.dealsSingle;

    filteredData = filteredData.filter(result => result.status == "open" && result.cf_package_type === type && result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0 && !result.cf_images[0]?.mx_image.includes("("));


    return filteredData;
  }
  getAllByExperiences(id: string): DealsModel[]{
    var filteredData = this.dealsSingle;

      filteredData = filteredData.filter(result => result.status == "open" && result.cf_experience_type === id && result.cf_destinations.length > 0 && result.cf_destinations[0]?.mx_city != "" && result.cf_images.length > 0 && !result.cf_images[0]?.mx_image.includes("("));


    return filteredData;
  }
}
